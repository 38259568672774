import { useEffect, useState } from "react";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import { Col, Input, Row } from "reactstrap";
import "../LifeStyleModifications/LifeStyle.css";
import { Card, Typography } from "@mui/material";

const DieticianDiet = () => {
  const [myDiet, setMyDiet] = useState();
  useEffect(() => {
    AxiosInstance.get("form/lifestyleSketchDiet")
      .then((response) => {
        setMyDiet(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  console.log("diet", myDiet);
  return (
    <div>
      <Typography variant="h5" className="mb-2" sx={{ fontWeight: "bold" }}>
        This is Patient's Current Nutrition
      </Typography>
      <Card
        sx={{ marginBottom: "15px", border: "1px solid black" }}
        elevation={5}
      >
        <div className="mb-3 dietHeadings">
          <h5>What you ate in the last 7 seven days</h5>
        </div>
        <div className="dietContent">
          <Row className="mb-2">
            <Col lg="6">
              <label className="questionLabel">
                1. Number of meals in a day :
              </label>
              <span className="inputDiet">{myDiet?.numberOfMealsPerDay}</span>
            </Col>
            <Col lg="6">
              <label className="questionLabel">
                2. What was included in the meals :
              </label>
              <span className="inputDiet">{myDiet?.itemsIncludedInMeals}</span>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col lg="6">
              <label className="questionLabel">
                3. How would you describe your diet :
              </label>
              <span className="inputDiet">{myDiet?.describeDiet}</span>
            </Col>
            <Col lg="6">
              <label className="questionLabel">
                4. Do you have breakfast regularly :{" "}
              </label>
              <span className="inputDiet">
                {myDiet?.regularBreakfast ? "Yes" : "No"}
              </span>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col lg="6">
              <label className="questionLabel">5. My appetite is :</label>
              <span className="inputDiet">{myDiet?.appetite}</span>
            </Col>
            <Col lg="6">
              <label className="questionLabel">
                6. What time do you have dinner :
              </label>
              <span className="inputDiet">{myDiet?.dinnerTime}</span>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col lg="6">
              <label className="questionLabel">
                7. Number of snacks per day :
              </label>
              <span className="inputDiet">{myDiet?.numberOfSnacksPerDay}</span>
            </Col>
          </Row>
        </div>
      </Card>
      <Card
        sx={{ marginBottom: "15px", border: "1px solid black" }}
        elevation={5}
      >
        <div className="mb-3 dietHeadings">
          <h5>Diet Choices and Behaviour</h5>
        </div>
        <div className="dietContent">
          <Row className="mb-2">
            <Col>
              <label className="questionLabel">
                1. Prefer to have a low carb diet :
              </label>
              <span className="inputDiet">{myDiet?.preferLowCarbDiet}</span>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <label className="questionLabel">
                2. I mostly prefer Vegetarian food and try to have more
                vegetables and fruits in my daily diet
              </label>
              <span className="inputDiet">{myDiet?.preferVegetarianFood}</span>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <label className="questionLabel">
                3. I try to avoid processed meat (Hamburgers, sausages, Hot
                dogs, etc.) :
              </label>
              <span className="inputDiet">{myDiet?.avoidProcessedMeat}</span>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <label className="questionLabel">
                4. I try to avoid highly processed foods (convenience meals,
                fast goods, cookies, chips, pretzels, etc) :
              </label>
              <span className="inputDiet">
                {myDiet?.avoidHighlyProcessedFoods}
              </span>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <label className="questionLabel">
                5. I do not drink sugar sweetened beverages (Pepsi, coke, etc.)
                :
              </label>
              <span className="inputDiet">
                {myDiet?.doNotDrinkSugarSweetenedBeverages}
              </span>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col lg={9}>
              <label className="questionLabel">
                6. I regularly consume whole grains, legumes(Peas and beans) and
                nuts and seeds (Chia, flax, pumpkin,, etc.) as part of my
                healthy diet practice :
              </label>
            </Col>
            <Col lg={3}>
              <span className="inputDiet">
                {myDiet?.regularlyConsumeWholeGrainsLegumes}
              </span>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <label className="questionLabel">
                7. I drink coffee at least once daily :
              </label>
              <span className="inputDiet">{myDiet?.drinkCoffee}</span>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <label className="questionLabel">
                8. I like to limit intake of dairy products (cheese, cream,
                Butter and Ghee, Yogurt, etc.) in my diet but do take few times
                every week :
              </label>
              <span className="inputDiet">
                {myDiet?.limitIntakeOfDairyProducts}
              </span>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <label className="questionLabel">
                9. I eat a diet which is low in added sugars, sodium, saturated
                fats, trans fats, and cholesterol :{" "}
              </label>
              <span className="inputDiet">
                {myDiet?.eatDietWhichIsLowInAddedSugars}
              </span>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <label className="questionLabel">
                10. I consciously try to limit or decrease salt in my meals :
              </label>
              <span className="inputDiet">
                {myDiet?.limitOrDecreaseSaltInMyMeals}
              </span>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <label className="questionLabel">
                11. I consciously try to eat food or fruits that are high in
                fiber :
              </label>
              <span className="inputDiet">{myDiet?.highInFiber}</span>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <label className="questionLabel">
                12. I consciously try to limit or decrease sugar in my diet :
              </label>
              <span className="inputDiet">
                {myDiet?.limitOrDecreaseSugarInMyDiet}
              </span>
            </Col>
          </Row>
        </div>
      </Card>
      <Card
        sx={{ marginBottom: "15px", border: "1px solid black" }}
        elevation={5}
      >
        <div className="mb-3 dietHeadings">
          <h5>General questions</h5>
        </div>
        <div className="dietContent">
          <Row className="mb-2">
            <Col lg="6">
              <label className="questionLabel">
                1. What is your current height (cm) :
              </label>
              <span className="inputDiet">{myDiet?.height}</span>
            </Col>
            <Col lg="6">
              <label className="questionLabel">
                2. What is your current weight (kg) :
              </label>
              <span className="inputDiet">{myDiet?.weight}</span>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col lg="6">
              <label className="questionLabel">3. BMI : </label>
              <span className="inputDiet">{myDiet?.bmi}</span>
            </Col>
            <Col lg="6">
              <label className="questionLabel">
                4. From whom have you received diet counselling :
              </label>
              <span className="inputDiet">
                {myDiet?.receivedDietCounselling}
              </span>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col lg="6">
              <label className="questionLabel">
                5. Would you like to consult with a dietitian in our network :{" "}
              </label>
              <span className="inputDiet">
                {myDiet?.consultDietitianInNetwork ? "Yes" : "No"}
              </span>
            </Col>
            <Col lg="6">
              <label className="questionLabel"></label>
            </Col>
          </Row>
        </div>
      </Card>
    </div>
  );
};

export default DieticianDiet;
