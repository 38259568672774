import { useEffect, useState } from "react";
import {
  Form,
  FormGroup,
  FormFeedback,
  FormControlLabel,
  FormText,
  Label,
  Input,
  Row,
  Col,
  Button,
  FormLabel,
  FormControl,
  RadioGroup,
} from "reactstrap";
import {
  Select,
  FormControlLabel,
  Checkbox,
  Grid,
  FormControl,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const predefinedDiseases = [
  "CVD",
  "Hypertension",
  "ObesityWeightLoss",
  "Diabetes",
  "Colorectal Cancer",
  "Prostate Cancer",
  "Cervical Cancer",
  "Oral Cancer",
];

const DieticianGeneralInfo = () => {
  const [generalInfoData, setGeneralInfoData] = useState([]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [registeredBusiness, setRegisteredBusiness] = useState(null);
  const [organizationName, setOrganizationName] = useState("");
  const [organizationPhoneNumber, setOrganizationPhoneNumber] = useState("");
  const [organizationBusinessTime, setOrganizationBusinessTime] = useState("");
  const [organizationAddress, setOrganizationAddress] = useState("");
  const [dietitianDetail, setDietitianDetail] = useState("");
  const [educationQualifications, setEducationQualifications] = useState("");
  const [yearsWorkingAsDietitian, setYearsWorkingAsDietitian] = useState();
  const [everWorkedAsClinicalDietitian, seteverWorkedAsClinicalDietitian] =
    useState(null);
  const [hospitalName, setHospitalName] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [yearsWorkingAtHospital, setYearsWorkingAtHospital] = useState("");
  const [specialization, setSpecialization] = useState("");
  const [
    nutritionConsultingForDiseasesForClients,
    setNutritionConsultingForDiseasesForClients,
  ] = useState("");
  const [historyOfWorkingWithChild, setHistoryOfWorkingWithChild] =
    useState("");
  const [
    detailsOfMethodologyOrDietaryGuidelinesFollowed,
    setDetailsOfMethodologyOrDietaryGuidelinesFollowed,
  ] = useState("");
  const [genderPreference, setGenderPreference] = useState("");
  const [
    childrenFromBirthTillThreeYearsAge,
    setChildrenFromBirthTillThreeYearsAge,
  ] = useState("");
  const [childrenFromTwoToTwelveYearsAge, setChildrenFromTwoToTwelveYearsAge] =
    useState("");
  const [
    childrenFromThirteenToTwentyYearsAge,
    setChildrenFromThirteenToTwentyYearsAge,
  ] = useState("");
  const [
    adultMaleFromTwentyOneToSixtyYearsAge,
    setAdultMaleFromTwentyOneToSixtyYearsAge,
  ] = useState("");
  const [
    adultFemaleFromTwentyOneToSixtyYearsAge,
    setAdultFemaleFromTwentyOneToSixtyYearsAgee,
  ] = useState("");
  const [seniorsOfAgeGreaterThenSixty, setSeniorsOfAgeGreaterThenSixty] =
    useState("");
  const [sharingReferences, setSharingReferences] = useState(null);
  const [diseasesOtherValue, setDiseasesOtherValue] = useState("");
  const [outstandingTestimonialsFileList, setOutstandingTestimonialsFileList] =
    useState([]);
  const [documentsFileList, setDocumentsFileList] = useState([]);
  const [formData, setFormData] = useState({
    CVD: {
      gender: "",
      ageGroup: [],
      years: "",
      patientsHelped: "",
    },
    Hypertension: {
      gender: "",
      ageGroup: [],
      years: "",
      patientsHelped: "",
    },
    ObesityWeightLoss: {
      gender: "",
      ageGroup: [],
      years: "",
      patientsHelped: "",
    },
    Diabetes: {
      gender: "",
      ageGroup: [],
      years: "",
      patientsHelped: "",
    },
    ColorectalCancer: {
      gender: "",
      ageGroup: [],
      years: "",
      patientsHelped: "",
    },
    ProstateCancer: {
      gender: "",
      ageGroup: [],
      years: "",
      patientsHelped: "",
    },
    CervicalCancer: {
      gender: "",
      ageGroup: [],
      years: "",
      patientsHelped: "",
    },
    OralCancer: {
      gender: "",
      ageGroup: [],
      years: "",
      patientsHelped: "",
    },
  });

  const [referenceDetail, setReferenceDetail] = useState({
    reference1: {
      name: "",
      mobile: "",
    },
    reference2: {
      name: "",
      mobile: "",
    },
    reference3: {
      name: "",
      mobile: "",
    },
  });

  const [preferredDayAndTime, setPreferredDayAndTime] = useState({
    Weekdays: [],
    time: "",
  });

  const [files, setFiles] = useState([]);
  const [testimonial, setTestimonial] = useState([]);
  const [fieldErrors, setFieldErrors] = useState({});
  const [selectedFile, setSelectedFile] = useState();
  const [fileName, setFileName] = useState(null);
  const [labPartnerCertificate, setLabPartnerCertificate] = useState([]);
  const [ISOreports, setISOReports] = useState(false);
  const [uploadFlag, setUploadFlag] = useState(false);
  const [showRegisteredOrganization, setShowRegisteredOrganization] =
    useState();
  const [
    showEverWorkedasClicnicalAdvisor,
    setShowEverWorkedasClicnicalAdvisor,
  ] = useState();
  const [showReferences, setShowReferences] = useState();
  const [testimonials, setTestimonials] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const handleRegisteredBusiness = (event) => {
    const isBusinessRegistered = event.target.value === "true";
    setRegisteredBusiness(isBusinessRegistered);
    setShowRegisteredOrganization(isBusinessRegistered);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      registeredBusiness: false,
    }));
  };

  const handleeverWorkedAsClinicalDietitian = (event) => {
    const isBusinessRegistered = event.target.value === "true";
    seteverWorkedAsClinicalDietitian(isBusinessRegistered);
    setShowEverWorkedasClicnicalAdvisor(isBusinessRegistered);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      everWorkedAsClinicalDietitian: false,
    }));
  };

  const handlesharingReferences = (event) => {
    const isBusinessRegistered = event.target.value === "true";
    setShowReferences(isBusinessRegistered);
    setSharingReferences(isBusinessRegistered);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      sharingReferences: false,
    }));
    if (!isBusinessRegistered) {
      setReferenceDetail({
        reference1: {
          name: "",
          mobile: "",
        },
        reference2: {
          name: "",
          mobile: "",
        },
        reference3: {
          name: "",
          mobile: "",
        },
      });
    }
  };

  const handleDietitianDetail = (event) => {
    setDietitianDetail(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      dietitianDetail: false,
    }));
  };

  const handleEducationQualification = (event) => {
    setEducationQualifications(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      educationQualifications: false,
    }));
  };

  const handleYearsWorkingAsDietitian = (event) => {
    setYearsWorkingAsDietitian(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      yearsWorkingAsDietitian: false,
    }));
  };

  const handleNutritionConsultingForDiseasesForClients = (event) => {
    const value = event.target.value;

    setNutritionConsultingForDiseasesForClients((prevString) => {
      const currentValues = prevString ? prevString.split(",") : [];
      if (currentValues.includes(value)) {
        const updatedValues = currentValues.filter(
          (member) => member !== value
        );
        return updatedValues.join(",");
      } else {
        currentValues.push(value);
        return currentValues.join(",");
      }
    });
  };

  const allDiseases = [
    "CVD",
    "Hypertension",
    "Obesity/Weight Loss",
    "Diabetes",
    "Colorectal Cancer",
    "Prostate Cancer",
    "Cervical Cancer",
    "Oral Cancer",
  ];

  const handleOtherDiseases = () => {
    const trimmedValue = diseasesOtherValue.trim();
    // if (trimmedValue !== "") {
    //   const otherValue = trimmedValue;
    //   setNutritionConsultingForDiseasesForClients((prevString) => {
    //     const currentValues = prevString ? prevString.split(",") : [];
    //     if (!currentValues.includes(otherValue)) {
    //       currentValues.push(otherValue);
    //     }
    //     return currentValues.join(",");
    //   });
    // }
    if (trimmedValue !== "") {
      // Convert the comma-separated string into an array for easier checking
      const currentValues = nutritionConsultingForDiseasesForClients
        ? nutritionConsultingForDiseasesForClients.split(",")
        : [];

      // Check if the entered "Other" disease is already part of the predefined diseases
      if (!currentValues.includes(trimmedValue)) {
        // Add "Other" disease if it's not already in the list
        setNutritionConsultingForDiseasesForClients((prevString) => {
          const updatedValues = [...currentValues, trimmedValue];
          return updatedValues.join(",");
        });
      }

      // Clear the input after handling
      // setDiseasesOtherValue("");
    }
  };

  const handleDiseasesOtherValue = (event) => {
    setDiseasesOtherValue(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      nutritionConsultingForDiseasesForClients: false,
    }));
  };

  const handleHistoryOfWorkingWithChild = (event) => {
    setHistoryOfWorkingWithChild(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      historyOfWorkingWithChild: false,
    }));
  };

  const handleDetailsOfMethodologyOrDietaryGuidelinesFollowed = (event) => {
    setDetailsOfMethodologyOrDietaryGuidelinesFollowed(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      detailsOfMethodologyOrDietaryGuidelinesFollowed: false,
    }));
  };

  const handleGenderPreference = (event) => {
    setGenderPreference(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      genderPreference: false,
    }));
  };

  const handleGenderChange = (disease, gender) => {
    setFieldErrors((prev) => ({
      ...prev,
      [disease]: { ...prev[disease], gender: !gender }, // Remove error if gender is selected
    }));
    setFormData((prevFormData) => ({
      ...prevFormData,
      [disease]: {
        ...(prevFormData?.[disease] ?? {}),
        gender,
      },
    }));
  };

  const handleAgeGroupChange = (disease, ageGroup) => {
    setFieldErrors((prev) => ({
      ...prev,
      [disease]: { ...prev[disease], ageGroup: false }, // Remove error when checkbox is checked
    }));
    setFormData((prevFormData) => {
      const existingAgeGroups = prevFormData?.[disease]?.ageGroup ?? [];
      const newAgeGroups = existingAgeGroups.includes(ageGroup)
        ? existingAgeGroups.filter((group) => group !== ageGroup)
        : [...existingAgeGroups, ageGroup];

      return {
        ...prevFormData,
        [disease]: {
          ...(prevFormData?.[disease] ?? {}),
          ageGroup: newAgeGroups,
        },
      };
    });
  };

  const handleYearsChange = (disease, years) => {
    setFieldErrors((prev) => ({
      ...prev,
      [disease]: { ...prev[disease], years: !years }, // Remove error if input is filled
    }));
    setFormData((prevFormData) => ({
      ...prevFormData,
      [disease]: {
        ...(prevFormData?.[disease] ?? {}),
        years,
      },
    }));
  };

  const handlePatientsHelpedChange = (disease, patientsHelped) => {
    setFieldErrors((prev) => ({
      ...prev,
      [disease]: { ...prev[disease], patientsHelped: !patientsHelped }, // Remove error if input is filled
    }));
    setFormData((prevFormData) => ({
      ...prevFormData,
      [disease]: {
        ...(prevFormData?.[disease] ?? {}),
        patientsHelped,
      },
    }));
  };

  const handleWeekDays = (event) => {
    const value = event.target.value;

    setPreferredDayAndTime((prevState) => {
      const currentValues = prevState.Weekdays;

      if (currentValues.includes(value)) {
        // Remove the day if it's already in the array
        return {
          ...prevState,
          Weekdays: currentValues.filter((day) => day !== value),
        };
      } else {
        // Add the day if it's not in the array
        return {
          ...prevState,
          Weekdays: [...currentValues, value],
        };
      }
    });

    // Optionally reset field errors if needed
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      preferredDayAndTime: false,
    }));
  };

  const handleTime = (name, value) => {
    setPreferredDayAndTime((prevState) => ({
      ...prevState,
      time: value,
    }));
  };

  const handleReferenceUpdate = (reference, field, value) => {
    setReferenceDetail((prevReferences) => ({
      ...prevReferences,
      [reference]: {
        ...(prevReferences?.[reference] ?? {}),
        [field]: value,
      },
    }));
    if (reference === "reference1" && field === "name") {
      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        reference1Name: false,
      }));
    }

    if (reference === "reference1" && field === "mobile") {
      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        reference1Mobile: false,
      }));
    }
  };

  const handleOrganizationName = (event) => {
    setOrganizationName(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      organizationName: false,
    }));
  };
  const handleOrganizationPhoneNumber = (event) => {
    const phoneNumber = event.target.value;
    setOrganizationPhoneNumber(phoneNumber);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      organizationPhoneNumber: false,
    }));
  };

  const handleOrganizationBusinessTime = (event) => {
    setOrganizationBusinessTime(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      organizationBusinessTime: false,
    }));
  };
  const handleOrganizationAddress = (event) => {
    setOrganizationAddress(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      organizationAddress: false,
    }));
  };
  const handleHospitalName = (event) => {
    setHospitalName(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      hospitalName: false,
    }));
  };
  const handleState = (event) => {
    setState(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      state: false,
    }));
  };
  const handleCity = (event) => {
    setCity(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      city: false,
    }));
  };
  const handleHowManyYears = (event) => {
    setYearsWorkingAtHospital(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      yearsWorkingAtHospital: false,
    }));
  };
  const handleSpecialization = (event) => {
    setSpecialization(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      specialization: false,
    }));
  };

  const yearsWorkingAsDietitianInt = parseInt(yearsWorkingAsDietitian, 10);
  const handleSubmit = async (e) => {
    e.preventDefault();
    let error = {};

    if (registeredBusiness == null) {
      error.registeredBusiness = true;
    }
    if (registeredBusiness == true) {
      if (organizationName == null || organizationName == "") {
        error.organizationName = true;
      }
      if (organizationPhoneNumber == null || organizationPhoneNumber == "") {
        error.organizationPhoneNumber = true;
      }
      if (organizationPhoneNumber != null) {
        if (organizationPhoneNumber.length !== 10) {
          error.organizationPhoneNumber = true;
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Enter a valid 10-digit Phone Number",
          });
        }
      }
      if (organizationBusinessTime == null || organizationBusinessTime == "") {
        error.organizationBusinessTime = true;
      }
      if (organizationAddress == null || organizationAddress == "") {
        error.organizationAddress = true;
      }
    }
    if (dietitianDetail == null || dietitianDetail == "") {
      error.dietitianDetail = true;
    }
    if (educationQualifications == null || educationQualifications == "") {
      error.educationQualifications = true;
    }
    if (yearsWorkingAsDietitian == null || yearsWorkingAsDietitian == "") {
      error.yearsWorkingAsDietitian = true;
    }
    if (everWorkedAsClinicalDietitian == null) {
      error.everWorkedAsClinicalDietitian = true;
    }
    if (everWorkedAsClinicalDietitian == true) {
      if (hospitalName == null || hospitalName == "") {
        error.hospitalName = true;
      }
      if (state == null || state == "") {
        error.state = true;
      }
      if (city == null || city == "") {
        error.city = true;
      }
      if (yearsWorkingAtHospital == null || yearsWorkingAtHospital == "") {
        error.yearsWorkingAtHospital = true;
      }
      if (specialization == null || specialization == "") {
        error.specialization = true;
      }
    }
    if (historyOfWorkingWithChild == null || historyOfWorkingWithChild == "") {
      error.historyOfWorkingWithChild = true;
    }
    if (
      !nutritionConsultingForDiseasesForClients ||
      nutritionConsultingForDiseasesForClients.trim() === ""
    ) {
      error.nutritionConsultingForDiseasesForClients = true;
    }
    if (
      detailsOfMethodologyOrDietaryGuidelinesFollowed == null ||
      detailsOfMethodologyOrDietaryGuidelinesFollowed == ""
    ) {
      error.detailsOfMethodologyOrDietaryGuidelinesFollowed = true;
    }
    if (genderPreference == null || genderPreference == "") {
      error.genderPreference = true;
    }
    if (sharingReferences == null) {
      error.sharingReferences = true;
    }
    if (preferredDayAndTime == null || preferredDayAndTime == "") {
      error.preferredDayAndTime = true;
    }

    Object.keys(formData).forEach((disease) => {
      const currentDiseaseData = formData[disease];

      // Check if any field in the current disease is filled
      const isAnyFieldFilled =
        currentDiseaseData?.gender ||
        currentDiseaseData?.ageGroup?.length ||
        currentDiseaseData?.years ||
        currentDiseaseData?.patientsHelped;

      // Set errors only if any field is filled but not all required fields
      if (isAnyFieldFilled) {
        if (!currentDiseaseData?.gender) {
          error[disease] = { ...error[disease], gender: true };
        }

        if (!currentDiseaseData?.ageGroup?.length) {
          error[disease] = { ...error[disease], ageGroup: true };
        }

        if (!currentDiseaseData?.years) {
          error[disease] = { ...error[disease], years: true };
        }

        if (!currentDiseaseData?.patientsHelped) {
          error[disease] = { ...error[disease], patientsHelped: true };
        }
      }
    });

    if (sharingReferences === true) {
      if (
        !referenceDetail.reference1.name ||
        referenceDetail.reference1.name.trim() === ""
      ) {
        error.reference1Name = true;
      }

      if (
        !referenceDetail.reference1.mobile ||
        referenceDetail.reference1.mobile.trim() === ""
      ) {
        error.reference1Mobile = true;
      }
      // if (
      //   referenceDetail.reference1.name &&
      //   referenceDetail.reference1.name.trim() !== "" &&
      //   referenceDetail.reference1.mobile &&
      //   referenceDetail.reference1.mobile.trim() !== ""
      // ) {
      //   error.reference1Name = false; // Set it to false if no errors
      //   error.reference1Mobile = false
      // }
    }

    setFieldErrors(error);
    if (Object.keys(error).length > 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Fill all the Mandatory Fields!",
      });
    } else {
      const formDataCheck = new FormData();

      formDataCheck.append("firstName", firstName);
      formDataCheck.append("lastName", lastName);
      formDataCheck.append("email", email);
      formDataCheck.append("phoneNumber", phoneNumber);
      formDataCheck.append("registeredBusiness", registeredBusiness);
      formDataCheck.append("organizationName", organizationName);
      formDataCheck.append("organizationPhoneNumber", organizationPhoneNumber);
      formDataCheck.append(
        "organizationBusinessTime",
        organizationBusinessTime
      );
      formDataCheck.append("organizationAddress", organizationAddress);
      formDataCheck.append("dietitianDetail", dietitianDetail);
      formDataCheck.append("educationQualifications", educationQualifications);
      formDataCheck.append(
        "yearsWorkingAsDietitian",
        yearsWorkingAsDietitianInt
      );
      formDataCheck.append(
        "everWorkedAsClinicalDietitian",
        everWorkedAsClinicalDietitian
      );
      formDataCheck.append("hospitalName", hospitalName);
      formDataCheck.append("state", state);
      formDataCheck.append("city", city);
      formDataCheck.append("yearsWorkingAtHospital", yearsWorkingAtHospital);
      formDataCheck.append("specialization", specialization);

      // For JSON objects, convert them to string before appending
      formDataCheck.append(
        "nutritionConsultingForDiseases",
        JSON.stringify(formData)
      );
      formDataCheck.append(
        "nutritionConsultingForDiseasesForClients",
        nutritionConsultingForDiseasesForClients
      );
      formDataCheck.append(
        "historyOfWorkingWithChild",
        historyOfWorkingWithChild
      );
      formDataCheck.append(
        "detailsOfMethodologyOrDietaryGuidelinesFollowed",
        detailsOfMethodologyOrDietaryGuidelinesFollowed
      );
      formDataCheck.append("genderPreference", genderPreference);
      formDataCheck.append(
        "childrenFromBirthTillThreeYearsAge",
        childrenFromBirthTillThreeYearsAge
      );
      formDataCheck.append(
        "childrenFromTwoToTwelveYearsAge",
        childrenFromTwoToTwelveYearsAge
      );
      formDataCheck.append(
        "childrenFromThirteenToTwentyYearsAge",
        childrenFromThirteenToTwentyYearsAge
      );
      formDataCheck.append(
        "adultMaleFromTwentyOneToSixtyYearsAge",
        adultMaleFromTwentyOneToSixtyYearsAge
      );
      formDataCheck.append(
        "adultFemaleFromTwentyOneToSixtyYearsAge",
        adultFemaleFromTwentyOneToSixtyYearsAge
      );
      formDataCheck.append(
        "seniorsOfAgeGreaterThenSixty",
        seniorsOfAgeGreaterThenSixty
      );
      formDataCheck.append("outstandingTestimonials", testimonials);
      formDataCheck.append("sharingReferences", sharingReferences);

      // For arrays or objects, JSON.stringify them before appending
      formDataCheck.append("referenceDetail", JSON.stringify(referenceDetail));
      formDataCheck.append(
        "preferredDayAndTime",
        JSON.stringify(preferredDayAndTime)
      );

      // For files, append them directly
      // files.forEach((file, index) => {
      //   formDataCheck.append(`files`, file);
      // });

      // testimonial.forEach((file, index) => {
      //   formDataCheck.append(`testimonialFiles`, file);
      // });
      try {
        const response = await AxiosInstance.post(
          "dietitian/add-dietitian",
          formDataCheck,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        Swal.fire({
          icon: "success",
          text: "Form data submitted successfully",
        });
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } catch (error) {
        toast.error("Failed to submit information", error);
      }
    }
  };
  console.log("testimonial", testimonial);
  const getDietitianData = () => {
    AxiosInstance.get("/dietitian/getDietitian")
      .then((response) => {
        setFirstName(response?.firstName);
        setLastName(response?.lastName);
        setEmail(response?.email);
        setPhoneNumber(response?.phoneNumber);
        setRegisteredBusiness(response?.registeredBusiness);
        setOrganizationAddress(response?.organizationAddress);
        setOrganizationBusinessTime(response?.organizationBusinessTime);
        setOrganizationName(response?.organizationName);
        setOrganizationPhoneNumber(response?.organizationPhoneNumber);
        setDietitianDetail(response?.dietitianDetail);
        setEducationQualifications(response?.educationQualifications);
        setYearsWorkingAsDietitian(response?.yearsWorkingAsDietitian);
        seteverWorkedAsClinicalDietitian(
          response?.everWorkedAsClinicalDietitian
        );
        setHospitalName(response?.hospitalName);
        setState(response?.state);
        setCity(response?.city);
        setYearsWorkingAtHospital(response?.yearsWorkingAtHospital);
        setSpecialization(response?.specialization);
        if (response?.nutritionConsultingForDiseasesForClients) {
          const diseasesArray =
            response.nutritionConsultingForDiseasesForClients.split(",");

          // Trim and convert each disease to lowercase for consistent comparison
          const trimmedDiseasesArray = diseasesArray.map((disease) =>
            disease.trim().toLowerCase()
          );

          // Predefined diseases also need to be in lowercase for case-insensitive comparison
          const predefinedDiseasesLowerCase = predefinedDiseases.map(
            (disease) => disease.toLowerCase()
          );

          // Separate predefined diseases and others
          const predefined = trimmedDiseasesArray.filter((disease) =>
            predefinedDiseasesLowerCase.includes(disease)
          );

          const others = trimmedDiseasesArray.filter(
            (disease) => !predefinedDiseasesLowerCase.includes(disease)
          );

          // Convert back to the original casing by mapping the predefined diseases
          const predefinedDiseasesMapped = predefinedDiseases.filter(
            (disease) => predefined.includes(disease.toLowerCase())
          );

          // Set predefined diseases back to the main state
          setNutritionConsultingForDiseasesForClients(
            predefinedDiseasesMapped.join(",")
          );

          // Set other diseases if there are any
          if (others.length > 0) {
            setDiseasesOtherValue(others.join(","));
          } else {
            setDiseasesOtherValue(""); // Clear "Other" field if no other diseases
          }
        }
        setHistoryOfWorkingWithChild(response?.historyOfWorkingWithChild);
        setDetailsOfMethodologyOrDietaryGuidelinesFollowed(
          response?.detailsOfMethodologyOrDietaryGuidelinesFollowed
        );
        setGenderPreference(response?.genderPreference);
        setChildrenFromBirthTillThreeYearsAge(
          response?.childrenFromBirthTillThreeYearsAge
        );
        setChildrenFromTwoToTwelveYearsAge(
          response?.childrenFromTwoToTwelveYearsAge
        );
        setChildrenFromThirteenToTwentyYearsAge(
          response?.childrenFromThirteenToTwentyYearsAge
        );
        setAdultMaleFromTwentyOneToSixtyYearsAge(
          response?.adultMaleFromTwentyOneToSixtyYearsAge
        );
        setAdultFemaleFromTwentyOneToSixtyYearsAgee(
          response?.adultFemaleFromTwentyOneToSixtyYearsAge
        );
        setSeniorsOfAgeGreaterThenSixty(response?.seniorsOfAgeGreaterThenSixty);
        setSharingReferences(response?.sharingReferences);
        setPreferredDayAndTime(
          response?.preferredDayAndTime
            ? JSON.parse(response?.preferredDayAndTime)
            : preferredDayAndTime
        );
        setReferenceDetail(JSON.parse(response?.referenceDetail));
        setFormData(
          response?.nutritionConsultingForDiseases
            ? JSON.parse(response?.nutritionConsultingForDiseases)
            : formData
        );
        setTestimonials(response?.outstandingTestimonials);
        setTestimonial(response?.outstandingTestimonialsFileList);
        setFiles(response?.documentsFileList);
        setOutstandingTestimonialsFileList(
          response?.outstandingTestimonialsFileList
        );
        setDocumentsFileList(response?.documentsFileList);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    getDietitianData();
  }, []);
  // const AttachTestimonial = async (event) => {
  //   event.preventDefault();
  //   const newFiles = Array.from(event.target.files); // Get the uploaded files
  //   setTestimonial((prevFiles) => [...prevFiles, ...newFiles]); // Add the new files to the existing files array

  //   // const formData = new FormData();
  //   // for (let i = 0; i < newFiles.length; i++) {
  //   //   formData.append("testimonialFiles[]", newFiles[i]); // Append each file to the form data
  //   // }
  // };

  const AttachTestimonial = async (event) => {
    event.preventDefault();

    const fileArray = Array.from(event.target.files);
    console.log("file: ", fileArray);

    // Ensure files are not empty
    if (fileArray.length === 0) {
      toast.error("No files selected.");
      return;
    }

    let fileCheck = fileArray[0]?.name?.split(".")?.pop();
    if (
      fileCheck === "gif" ||
      fileCheck === "jfif" ||
      fileCheck === "csv" ||
      fileCheck === "xls"
    ) {
      toast.error("GIF, CSV, XLS, and JFIF files are not allowed");
      return;
    }

    const formData = new FormData();

    // Append files to formData
    formData.append("fileType", "Testimonial");
    for (const file of fileArray) {
      formData.append("file", file); // Ensure the key matches backend expectations
    }

    try {
      const response = await AxiosInstance.post(
        "dietitian/dietitian-files",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      toast.success("Documents Uploaded Successfully");
    } catch (error) {
      console.error("Error uploading file:", error);
      toast.error("An error occurred while uploading the files.");
    }
  };

  const handleAttachCopies = async (event) => {
    event.preventDefault();

    const fileArray = Array.from(event.target.files);
    console.log("file: ", fileArray);

    // Ensure files are not empty
    if (fileArray.length === 0) {
      toast.error("No files selected.");
      return;
    }

    let fileCheck = fileArray[0]?.name?.split(".")?.pop();
    if (
      fileCheck === "gif" ||
      fileCheck === "jfif" ||
      fileCheck === "csv" ||
      fileCheck === "xls"
    ) {
      toast.error("GIF, CSV, XLS, and JFIF files are not allowed");
      return;
    }

    const formData = new FormData();

    // Append files to formData
    formData.append("fileType", "Documents");
    for (const file of fileArray) {
      formData.append("file", file);
    }

    try {
      const response = await AxiosInstance.post(
        "dietitian/dietitian-files",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      toast.success("Documents Uploaded Successfully");
    } catch (error) {
      console.error("Error uploading file:", error);
      toast.error("An error occurred while uploading the files.");
    }
  };

  const states = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jammu and Kashmir",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttarakhand",
    "Uttar Pradesh",
    "West Bengal",
    "Andaman and Nicobar Islands",
    "Chandigarh",
    "Dadra and Nagar Haveli",
    "Daman and Diu",
    "Delhi",
    "Lakshadweep",
    "Puducherry",
  ];
  const IOSCertificate = "ISOCertificate";

  const nablCertificates = labPartnerCertificate
    .filter((certificate) => certificate.license === "NABLCertificate")
    .map((certificate) => certificate.documentLink);

  const isoCertificates = labPartnerCertificate
    .filter((certificate) => certificate.license === "ISOCertificate")
    .map((certificate) => certificate.documentLink);

  const addTestimonial = () => {
    if (testimonials?.length < 3) {
      setTestimonials([...testimonials, ""]);
    }
  };

  const handleInputChange = (index, value) => {
    const newTestimonials = [...testimonials];
    newTestimonials[index] = value;
    setTestimonials(newTestimonials);
  };

  useEffect(() => {
    if (testimonials?.length >= 3) {
      setButtonDisabled(true);
    } else {
      setButtonDisabled(false);
    }
  }, [testimonials]);

  const isChecked = (category, value) => {
    return (
      (formData &&
        category &&
        value &&
        formData[category]?.ageGroup?.includes(value)) ||
      false
    );
  };
  const lastThreeFiles = documentsFileList?.slice(-3);
  const lastThreeTestimonials = outstandingTestimonialsFileList?.slice(-3);

  return (
    <>
      <Form
        style={{
          border: "1px solid lightgrey",
          padding: "15px",
          marginTop: "25px",
          boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
          fontSize: "18px",
        }}
      >
        <Row>
          <Col md={3}>
            <FormGroup>
              <Label for="exampleEmail">
                First Name of Clinical Dietician/Nutritionist
              </Label>
              <Input disabled id="" name="name" value={firstName} type="text" />
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label for="exampleEmail">
                Last Name of Clinical Dietician/Nutritionist
              </Label>
              <Input id="" disabled name="name" value={lastName} type="text" />
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label for="examplePassword">Email</Label>
              <Input disabled id="" name="email" value={email} type="email" />
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label for="exampleEmail">Phone Number</Label>
              <Input
                disabled
                id="phoneNumber"
                name="registrationNumber"
                value={phoneNumber}
                type="number"
              />
            </FormGroup>
          </Col>
        </Row>
        <hr style={{ border: "1px solid #000000" }}></hr>
        <Row className="mt-4">
          <Col md={12}>
            <FormGroup>
              <span
                style={{
                  color: fieldErrors?.registeredBusiness ? "red" : "inherit",
                }}
              >
                Do you have a registered practice/organization for your business
                ?
              </span>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <Input
                name="registeredOrganization"
                type="radio"
                value="true"
                checked={registeredBusiness === true}
                onChange={(e) => {
                  handleRegisteredBusiness(e);
                }}
                style={{ cursor: "pointer" }}
              />
              &nbsp;
              <Label check className="ml-1">
                Yes
              </Label>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <Input
                name="registeredOrganization"
                type="radio"
                value="false"
                checked={registeredBusiness === false}
                onChange={(e) => {
                  handleRegisteredBusiness(e);
                }}
                style={{ cursor: "pointer" }}
              />
              &nbsp;
              <Label check className="ml-1">
                No
              </Label>
            </FormGroup>
          </Col>
        </Row>
        {(showRegisteredOrganization || registeredBusiness) && (
          <>
            <Row>
              <Col md={4}>
                <FormGroup>
                  <Label for="organizationName">Name</Label>
                  <Input
                    id={`organizationName`}
                    name="organizationName"
                    placeholder="Enter Name"
                    type="text"
                    value={organizationName}
                    onChange={(e) => {
                      handleOrganizationName(e);
                    }}
                    style={{
                      borderColor: fieldErrors?.organizationName
                        ? "red"
                        : "inherit",
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label for={`phoneNumber`}>Phone Number</Label>
                  <Input
                    id={`phoneNumber`}
                    name="phoneNumber"
                    placeholder="Enter Phone Number"
                    type="number"
                    onChange={(e) => {
                      handleOrganizationPhoneNumber(e);
                    }}
                    value={organizationPhoneNumber}
                    style={{
                      borderColor: fieldErrors?.organizationPhoneNumber
                        ? "red"
                        : "lightgrey",
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label for={`organizationBusinessTime`}>Business Time</Label>
                  <Input
                    id={`organizationBusinessTime`}
                    name="organizationBusinessTime"
                    placeholder="Enter Email Id"
                    type="time"
                    onChange={(e) => {
                      handleOrganizationBusinessTime(e);
                    }}
                    value={organizationBusinessTime}
                    style={{
                      borderColor: fieldErrors?.organizationBusinessTime
                        ? "red"
                        : "inherit",
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md={12}>
                <FormGroup>
                  <Label for={`organizationAddress`}>Address</Label>
                  <Input
                    id={`organizationAddress`}
                    name="phoneNumber"
                    placeholder="Enter Address"
                    type="textarea"
                    onChange={(e) => {
                      handleOrganizationAddress(e);
                    }}
                    value={organizationAddress}
                    style={{
                      borderColor: fieldErrors?.organizationAddress
                        ? "red"
                        : "inherit",
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
          </>
        )}

        <hr style={{ border: "1px solid #000000" }}></hr>
        <Row>
          <Col>
            <FormGroup>
              <Label for="exampleEmail">
                Please narrate briefly about yourself.
              </Label>
              <Input
                id=""
                name="name"
                value={dietitianDetail}
                onChange={(e) => {
                  handleDietitianDetail(e);
                }}
                placeholder="Narrate about yourself"
                type="textarea"
                style={{
                  borderColor: fieldErrors?.dietitianDetail ? "red" : "inherit",
                }}
              />
            </FormGroup>
          </Col>
        </Row>
        <hr style={{ border: "1px solid #000000" }}></hr>
        <Row>
          <Col md={12}>
            <FormGroup>
              <Label for="exampleEmail">
                List all your educational qualifications as a Clinical
                Dietician.
              </Label>
              <Input
                id=""
                name="name"
                value={educationQualifications}
                onChange={(e) => {
                  handleEducationQualification(e);
                }}
                placeholder="List your educational qualification"
                type="textarea"
                style={{
                  borderColor: fieldErrors?.educationQualifications
                    ? "red"
                    : "inherit",
                }}
              />
            </FormGroup>
          </Col>
        </Row>
        <hr style={{ border: "1px solid #000000" }}></hr>
        <Row className="mt-4">
          <Col md={4}>
            How long (years) you have now worked in this capacity?
          </Col>
          <Col md={4}>
            <FormGroup>
              <Input
                id=""
                name="name"
                value={yearsWorkingAsDietitian}
                onChange={(e) => {
                  handleYearsWorkingAsDietitian(e);
                }}
                placeholder="Enter years"
                type="number"
                style={{
                  borderColor: fieldErrors?.yearsWorkingAsDietitian
                    ? "red"
                    : "inherit",
                }}
              />
            </FormGroup>
          </Col>
        </Row>
        <hr style={{ border: "1px solid #000000" }}></hr>
        <Row className="mt-4">
          <Col md={12}>
            <FormGroup>
              <span
                style={{
                  color: fieldErrors?.everWorkedAsClinicalDietitian
                    ? "red"
                    : "inherit",
                }}
              >
                Have you ever worked as a clinical dietician in a hospital or
                clinic setting dealing directly with patients and taking care of
                their dietary needs after illness?
              </span>{" "}
              &nbsp;&nbsp;&nbsp;
              <Input
                name="everWorkedAsClinicalDietitian"
                type="radio"
                value="true"
                checked={everWorkedAsClinicalDietitian === true}
                onChange={(e) => {
                  handleeverWorkedAsClinicalDietitian(e);
                }}
                style={{ cursor: "pointer" }}
              />
              &nbsp;
              <Label check className="ml-1">
                Yes
              </Label>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <Input
                name="everWorkedAsClinicalDietitian"
                type="radio"
                value="false"
                checked={everWorkedAsClinicalDietitian === false}
                onChange={(e) => {
                  handleeverWorkedAsClinicalDietitian(e);
                }}
                style={{ cursor: "pointer" }}
              />
              &nbsp;
              <Label check className="ml-1">
                No
              </Label>
            </FormGroup>
          </Col>
        </Row>
        {(showEverWorkedasClicnicalAdvisor ||
          everWorkedAsClinicalDietitian) && (
          <>
            <Row></Row>
            <Row>
              <Col md={4}>
                <Label>Name of Hospital / Clinic</Label>
                <FormGroup>
                  <Input
                    id=""
                    name="name"
                    value={hospitalName}
                    onChange={(e) => {
                      handleHospitalName(e);
                    }}
                    placeholder="Enter name of hospital / clinic"
                    type="text"
                    className="mt-2"
                    style={{
                      borderColor: fieldErrors?.hospitalName
                        ? "red"
                        : "inherit",
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <Label>State</Label>
                <FormGroup>
                  <Input
                    type="select"
                    name="state"
                    id="state"
                    value={state}
                    onChange={(e) => {
                      handleState(e);
                    }}
                    className="mt-2"
                    style={{
                      borderColor: fieldErrors?.state ? "red" : "inherit",
                    }}
                  >
                    <option value="">Select State</option>
                    {states.map((state, index) => (
                      <option key={index} value={state}>
                        {state}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
              <Col md={4}>
                <Label>City</Label>
                <FormGroup>
                  <Input
                    id=""
                    name="name"
                    value={city}
                    onChange={(e) => {
                      handleCity(e);
                    }}
                    placeholder="Enter City"
                    type="text"
                    className="mt-2"
                    style={{
                      borderColor: fieldErrors?.city ? "red" : "inherit",
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <Label>How many years? </Label>
                <FormGroup>
                  <Input
                    type="select"
                    id=""
                    name="yearsWorkingAtHospital"
                    value={yearsWorkingAtHospital}
                    onChange={(e) => {
                      handleHowManyYears(e);
                    }}
                    className="mt-2"
                    style={{
                      borderColor: fieldErrors?.yearsWorkingAtHospital
                        ? "red"
                        : "inherit",
                    }}
                  >
                    <option value="">Select years</option>
                    <option value="less than 1 year">Less than 1 year</option>
                    <option value="1-2 years">1-2 years</option>
                    <option value="3-4 years">3-4 years</option>
                    <option value="5 years or more">5 years or more</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col md={4}>
                <Label>Which Specialization : </Label>
                <FormGroup>
                  <Input
                    type="select"
                    id=""
                    name="specialization"
                    onChange={(e) => {
                      handleSpecialization(e);
                    }}
                    value={specialization}
                    className="mt-2"
                    style={{
                      borderColor: fieldErrors?.specialization
                        ? "red"
                        : "inherit",
                    }}
                  >
                    <option value="">Select Specialization</option>
                    <option value="CVD">CVD</option>
                    <option value="hypertension">Hypertension</option>
                    <option value="obesity/weightLoss">
                      Obesity / Weight Loss
                    </option>
                    <option value="Diabetes">Diabetes</option>
                    <option value="ColorectalCancer">ColorectalCancer</option>
                    <option value="ProstateCancer">ProstateCancer</option>
                    <option value="CervicalCancer">CervicalCancer</option>
                    <option value="Oral Cancer ">Oral Cancer </option>
                  </Input>
                </FormGroup>
              </Col>
            </Row>
          </>
        )}
        <hr style={{ border: "1px solid #000000" }}></hr>
        <Row>
          <Col>
            <FormGroup>
              <Label>
                For which of the following diseases, you have provided or
                currently providing Nutrition consulting?
              </Label>
            </FormGroup>
          </Col>
        </Row>

        <TableContainer
          sx={{ border: "1px solid black", borderRadius: "5px" }}
          elevation={5}
          className="mt-3 mb-3"
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    borderRight: "1px solid black",
                    fontSize: "large",
                    borderBottom: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  Disease
                </TableCell>
                <TableCell
                  sx={{
                    borderRight: "1px solid black",
                    fontSize: "large",
                    borderBottom: "1px solid black",
                    width: "17%",
                    textAlign: "center",
                  }}
                >
                  Gender
                </TableCell>
                <TableCell
                  sx={{
                    borderRight: "1px solid black",
                    fontSize: "large",
                    borderBottom: "1px solid black",
                    width: "17%",
                    textAlign: "center",
                  }}
                >
                  Age Group
                </TableCell>
                <TableCell
                  sx={{
                    borderRight: "1px solid black",
                    fontSize: "large",
                    borderBottom: "1px solid black",
                    width: "17%",
                    textAlign: "center",
                  }}
                >
                  How many years
                </TableCell>
                <TableCell
                  sx={{
                    borderRight: "1px solid black",
                    fontSize: "large",
                    borderBottom: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  How many patients/people you have helped so far? (Approx No.)
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Label>CVD</Label>
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Input
                    type="select"
                    id=""
                    name="educationalQualification"
                    value={formData?.CVD?.gender}
                    onChange={(e) => {
                      handleGenderChange("CVD", e.target.value);
                    }}
                    style={{
                      borderColor: fieldErrors?.CVD?.gender
                        ? "red"
                        : "lightgrey",
                    }}
                    className="mt-2"
                  >
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="both">Both</option>
                  </Input>
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Row>
                    <Col>
                      <div className="age-group-label">0-3</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="0-3"
                              checked={isChecked("CVD", "0-3")}
                              sx={{
                                color: fieldErrors?.CVD?.ageGroup
                                  ? "red"
                                  : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.CVD?.ageGroup
                                    ? "red"
                                    : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          // checked={formData?.CVD.ageGroup}
                          onChange={(e) => {
                            handleAgeGroupChange("CVD", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <div className="age-group-label">3-13</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="3-13"
                              checked={isChecked("CVD", "3-13")}
                              sx={{
                                color: fieldErrors?.CVD?.ageGroup
                                  ? "red"
                                  : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.CVD?.ageGroup
                                    ? "red"
                                    : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("CVD", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="age-group-label">13-21</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="13-21"
                              checked={isChecked("CVD", "13-21")}
                              sx={{
                                color: fieldErrors?.CVD?.ageGroup
                                  ? "red"
                                  : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.CVD?.ageGroup
                                    ? "red"
                                    : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("CVD", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <div className="age-group-label">&gt;21</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value=">21"
                              checked={isChecked("CVD", ">21")}
                              sx={{
                                color: fieldErrors?.CVD?.ageGroup
                                  ? "red"
                                  : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.CVD?.ageGroup
                                    ? "red"
                                    : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("CVD", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bolder",
                    border: "1px solid lightgrey",
                    fontSize: "20px",
                  }}
                >
                  <Input
                    id=""
                    name="name"
                    value={formData?.CVD?.years}
                    onChange={(e) => {
                      handleYearsChange("CVD", e.target.value);
                    }}
                    placeholder="Enter years"
                    type="number"
                    style={{
                      borderColor: fieldErrors?.CVD?.years
                        ? "red"
                        : "lightgrey",
                    }}
                    className="mt-2"
                  />
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bolder",
                    border: "1px solid lightgrey",
                    fontSize: "20px",
                  }}
                >
                  <Input
                    id=""
                    name="name"
                    value={formData?.CVD?.patientsHelped}
                    onChange={(e) => {
                      handlePatientsHelpedChange("CVD", e.target.value);
                    }}
                    placeholder="Enter number of patients you have helped"
                    type="number"
                    style={{
                      borderColor: fieldErrors?.CVD?.patientsHelped
                        ? "red"
                        : "lightgrey",
                    }}
                    className="mt-2"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Label>Hypertension</Label>
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Input
                    type="select"
                    id=""
                    name="educationalQualification"
                    value={formData?.Hypertension?.gender}
                    onChange={(e) => {
                      handleGenderChange("Hypertension", e.target.value);
                    }}
                    style={{
                      borderColor: fieldErrors?.Hypertension?.gender
                        ? "red"
                        : "lightgrey",
                    }}
                    className="mt-2"
                  >
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="both">Both</option>
                  </Input>
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Row>
                    <Col>
                      <div className="age-group-label">0-3</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="0-3"
                              checked={isChecked("Hypertension", "0-3")}
                              sx={{
                                color: fieldErrors?.Hypertension?.ageGroup
                                  ? "red"
                                  : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.Hypertension?.ageGroup
                                    ? "red"
                                    : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange(
                              "Hypertension",
                              e.target.value
                            );
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <div className="age-group-label">3-13</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="3-13"
                              checked={isChecked("Hypertension", "3-13")}
                              sx={{
                                color: fieldErrors?.Hypertension?.ageGroup
                                  ? "red"
                                  : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.Hypertension?.ageGroup
                                    ? "red"
                                    : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange(
                              "Hypertension",
                              e.target.value
                            );
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="age-group-label">13-21</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="13-21"
                              checked={isChecked("Hypertension", "13-21")}
                              sx={{
                                color: fieldErrors?.Hypertension?.ageGroup
                                  ? "red"
                                  : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.Hypertension?.ageGroup
                                    ? "red"
                                    : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange(
                              "Hypertension",
                              e.target.value
                            );
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <div className="age-group-label">&gt;21</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value=">21"
                              checked={isChecked("Hypertension", ">21")}
                              sx={{
                                color: fieldErrors?.Hypertension?.ageGroup
                                  ? "red"
                                  : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.Hypertension?.ageGroup
                                    ? "red"
                                    : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange(
                              "Hypertension",
                              e.target.value
                            );
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bolder",
                    border: "1px solid lightgrey",
                    fontSize: "20px",
                  }}
                >
                  <Input
                    id=""
                    name="name"
                    value={formData?.Hypertension?.years}
                    onChange={(e) => {
                      handleYearsChange("Hypertension", e.target.value);
                    }}
                    placeholder="Enter years"
                    type="number"
                    style={{
                      borderColor: fieldErrors?.Hypertension?.years
                        ? "red"
                        : "lightgrey",
                    }}
                    className="mt-2"
                  />
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bolder",
                    border: "1px solid lightgrey",
                    fontSize: "20px",
                  }}
                >
                  <Input
                    id=""
                    name="name"
                    value={formData?.Hypertension?.patientsHelped}
                    onChange={(e) => {
                      handlePatientsHelpedChange(
                        "Hypertension",
                        e.target.value
                      );
                    }}
                    placeholder="Enter number of patients you have helped"
                    type="number"
                    style={{
                      borderColor: fieldErrors?.Hypertension?.patientsHelped
                        ? "red"
                        : "lightgrey",
                    }}
                    className="mt-2"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Label>Obesity/Weight Loss</Label>
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Input
                    type="select"
                    id=""
                    name="educationalQualification"
                    onChange={(e) => {
                      handleGenderChange("ObesityWeightLoss", e.target.value);
                    }}
                    value={formData?.["ObesityWeightLoss"]?.gender}
                    className="mt-2"
                    style={{
                      borderColor: fieldErrors?.ObesityWeightLoss?.gender
                        ? "red"
                        : "lightgrey",
                    }}
                  >
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="both">Both</option>
                  </Input>
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Row>
                    <Col>
                      <div className="age-group-label">0-3</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="0-3"
                              checked={isChecked("ObesityWeightLoss", "0-3")}
                              sx={{
                                color: fieldErrors?.ObesityWeightLoss?.ageGroup
                                  ? "red"
                                  : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.ObesityWeightLoss
                                    ?.ageGroup
                                    ? "red"
                                    : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange(
                              "ObesityWeightLoss",
                              e.target.value
                            );
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <div className="age-group-label">3-13</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="3-13"
                              checked={isChecked("ObesityWeightLoss", "3-13")}
                              sx={{
                                color: fieldErrors?.ObesityWeightLoss?.ageGroup
                                  ? "red"
                                  : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.ObesityWeightLoss
                                    ?.ageGroup
                                    ? "red"
                                    : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange(
                              "ObesityWeightLoss",
                              e.target.value
                            );
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="age-group-label">13-21</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="13-21"
                              checked={isChecked("ObesityWeightLoss", "13-21")}
                              sx={{
                                color: fieldErrors?.ObesityWeightLoss?.ageGroup
                                  ? "red"
                                  : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.ObesityWeightLoss
                                    ?.ageGroup
                                    ? "red"
                                    : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange(
                              "ObesityWeightLoss",
                              e.target.value
                            );
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <div className="age-group-label">&gt;21</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value=">21"
                              checked={isChecked("ObesityWeightLoss", ">21")}
                              sx={{
                                color: fieldErrors?.ObesityWeightLoss?.ageGroup
                                  ? "red"
                                  : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.ObesityWeightLoss
                                    ?.ageGroup
                                    ? "red"
                                    : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange(
                              "ObesityWeightLoss",
                              e.target.value
                            );
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bolder",
                    border: "1px solid lightgrey",
                    fontSize: "20px",
                  }}
                >
                  <Input
                    id=""
                    name="name"
                    value={formData?.["ObesityWeightLoss"]?.years}
                    onChange={(e) => {
                      handleYearsChange("ObesityWeightLoss", e.target.value);
                    }}
                    placeholder="Enter years"
                    type="text"
                    className="mt-2"
                    style={{
                      borderColor: fieldErrors?.ObesityWeightLoss?.years
                        ? "red"
                        : "lightgrey",
                    }}
                  />
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bolder",
                    border: "1px solid lightgrey",
                    fontSize: "20px",
                  }}
                >
                  <Input
                    id=""
                    name="name"
                    value={formData?.["ObesityWeightLoss"]?.patientsHelped}
                    onChange={(e) => {
                      handlePatientsHelpedChange(
                        "ObesityWeightLoss",
                        e.target.value
                      );
                    }}
                    placeholder="Enter number of patients you have helped"
                    type="text"
                    style={{
                      borderColor: fieldErrors?.ObesityWeightLoss
                        ?.patientsHelped
                        ? "red"
                        : "lightgrey",
                    }}
                    className="mt-2"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Label>Diabetes</Label>
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Input
                    type="select"
                    id=""
                    name="educationalQualification"
                    onChange={(e) => {
                      handleGenderChange("Diabetes", e.target.value);
                    }}
                    value={formData?.Diabetes?.gender}
                    style={{
                      borderColor: fieldErrors?.Diabetes?.gender
                        ? "red"
                        : "lightgrey",
                    }}
                    className="mt-2"
                  >
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="both">Both</option>
                  </Input>
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Row>
                    <Col>
                      <div className="age-group-label">0-3</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="0-3"
                              checked={isChecked("Diabetes", "0-3")}
                              sx={{
                                color: fieldErrors?.Diabetes?.ageGroup
                                  ? "red"
                                  : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.Diabetes?.ageGroup
                                    ? "red"
                                    : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("Diabetes", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <div className="age-group-label">3-13</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="3-13"
                              checked={isChecked("Diabetes", "3-13")}
                              sx={{
                                color: fieldErrors?.Diabetes?.ageGroup
                                  ? "red"
                                  : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.Diabetes?.ageGroup
                                    ? "red"
                                    : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("Diabetes", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="age-group-label">13-21</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="13-21"
                              checked={isChecked("Diabetes", "13-21")}
                              sx={{
                                color: fieldErrors?.Diabetes?.ageGroup
                                  ? "red"
                                  : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.Diabetes?.ageGroup
                                    ? "red"
                                    : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("Diabetes", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <div className="age-group-label">&gt;21</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value=">21"
                              checked={isChecked("Diabetes", ">21")}
                              sx={{
                                color: fieldErrors?.Diabetes?.ageGroup
                                  ? "red"
                                  : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.Diabetes?.ageGroup
                                    ? "red"
                                    : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("Diabetes", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bolder",
                    border: "1px solid lightgrey",
                    fontSize: "20px",
                  }}
                >
                  <Input
                    id=""
                    name="name"
                    value={formData?.Diabetes?.years}
                    onChange={(e) => {
                      handleYearsChange("Diabetes", e.target.value);
                    }}
                    placeholder="Enter years"
                    style={{
                      borderColor: fieldErrors?.Diabetes?.years
                        ? "red"
                        : "lightgrey",
                    }}
                    type="number"
                    className="mt-2"
                  />
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bolder",
                    border: "1px solid lightgrey",
                    fontSize: "20px",
                  }}
                >
                  <Input
                    id=""
                    name="name"
                    value={formData?.Diabetes?.patientsHelped}
                    onChange={(e) => {
                      handlePatientsHelpedChange("Diabetes", e.target.value);
                    }}
                    placeholder="Enter number of patients you have helped"
                    type="number"
                    style={{
                      borderColor: fieldErrors?.Diabetes?.patientsHelped
                        ? "red"
                        : "lightgrey",
                    }}
                    className="mt-2"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Label>Colorectal Cancer</Label>
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Input
                    type="select"
                    id=""
                    name="educationalQualification"
                    onChange={(e) => {
                      handleGenderChange("ColorectalCancer", e.target.value);
                    }}
                    style={{
                      borderColor: fieldErrors?.ColorectalCancer?.gender
                        ? "red"
                        : "lightgrey",
                    }}
                    value={formData?.["ColorectalCancer"]?.gender}
                    className="mt-2"
                  >
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="both">Both</option>
                  </Input>
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Row>
                    <Col>
                      <div className="age-group-label">0-3</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="0-3"
                              checked={isChecked("ColorectalCancer", "0-3")}
                              sx={{
                                color: fieldErrors?.ColorectalCancer?.ageGroup
                                  ? "red"
                                  : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.ColorectalCancer?.ageGroup
                                    ? "red"
                                    : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange(
                              "ColorectalCancer",
                              e.target.value
                            );
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <div className="age-group-label">3-13</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="3-13"
                              checked={isChecked("ColorectalCancer", "3-13")}
                              sx={{
                                color: fieldErrors?.ColorectalCancer?.ageGroup
                                  ? "red"
                                  : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.ColorectalCancer?.ageGroup
                                    ? "red"
                                    : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange(
                              "ColorectalCancer",
                              e.target.value
                            );
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="age-group-label">13-21</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="13-21"
                              checked={isChecked("ColorectalCancer", "13-21")}
                              sx={{
                                color: fieldErrors?.ColorectalCancer?.ageGroup
                                  ? "red"
                                  : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.ColorectalCancer?.ageGroup
                                    ? "red"
                                    : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange(
                              "ColorectalCancer",
                              e.target.value
                            );
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <div className="age-group-label">&gt;21</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value=">21"
                              checked={isChecked("ColorectalCancer", ">21")}
                              sx={{
                                color: fieldErrors?.ColorectalCancer?.ageGroup
                                  ? "red"
                                  : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.ColorectalCancer?.ageGroup
                                    ? "red"
                                    : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange(
                              "ColorectalCancer",
                              e.target.value
                            );
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bolder",
                    border: "1px solid lightgrey",
                    fontSize: "20px",
                  }}
                >
                  <Input
                    id=""
                    name="name"
                    value={formData?.["ColorectalCancer"]?.years}
                    onChange={(e) => {
                      handleYearsChange("ColorectalCancer", e.target.value);
                    }}
                    placeholder="Enter years"
                    type="number"
                    className="mt-2"
                    style={{
                      borderColor: fieldErrors?.ColorectalCancer?.years
                        ? "red"
                        : "lightgrey",
                    }}
                  />
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bolder",
                    border: "1px solid lightgrey",
                    fontSize: "20px",
                  }}
                >
                  <Input
                    id=""
                    name="name"
                    value={formData?.["ColorectalCancer"]?.patientsHelped}
                    onChange={(e) => {
                      handlePatientsHelpedChange(
                        "ColorectalCancer",
                        e.target.value
                      );
                    }}
                    style={{
                      borderColor: fieldErrors?.ColorectalCancer?.patientsHelped
                        ? "red"
                        : "lightgrey",
                    }}
                    placeholder="Enter number of patients you have helped"
                    type="number"
                    className="mt-2"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Label>Prostate Cancer</Label>
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Input
                    type="select"
                    id=""
                    name="educationalQualification"
                    onChange={(e) => {
                      handleGenderChange("ProstateCancer", e.target.value);
                    }}
                    value={formData?.["ProstateCancer"]?.gender}
                    style={{
                      borderColor: fieldErrors?.ProstateCancer?.gender
                        ? "red"
                        : "lightgrey",
                    }}
                    className="mt-2"
                  >
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="both">Both</option>
                  </Input>
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Row>
                    <Col>
                      <div className="age-group-label">0-3</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="0-3"
                              checked={isChecked("ProstateCancer", "0-3")}
                              sx={{
                                color: fieldErrors?.ProstateCancer?.ageGroup
                                  ? "red"
                                  : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.ProstateCancer?.ageGroup
                                    ? "red"
                                    : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange(
                              "ProstateCancer",
                              e.target.value
                            );
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <div className="age-group-label">3-13</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="3-13"
                              checked={isChecked("ProstateCancer", "3-13")}
                              sx={{
                                color: fieldErrors?.ProstateCancer?.ageGroup
                                  ? "red"
                                  : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.ProstateCancer?.ageGroup
                                    ? "red"
                                    : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange(
                              "ProstateCancer",
                              e.target.value
                            );
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="age-group-label">13-21</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="13-21"
                              checked={isChecked("ProstateCancer", "13-21")}
                              sx={{
                                color: fieldErrors?.ProstateCancer?.ageGroup
                                  ? "red"
                                  : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.ProstateCancer?.ageGroup
                                    ? "red"
                                    : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange(
                              "ProstateCancer",
                              e.target.value
                            );
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <div className="age-group-label">&gt;21</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value=">21"
                              checked={isChecked("ProstateCancer", ">21")}
                              sx={{
                                color: fieldErrors?.ProstateCancer?.ageGroup
                                  ? "red"
                                  : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.ProstateCancer?.ageGroup
                                    ? "red"
                                    : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange(
                              "ProstateCancer",
                              e.target.value
                            );
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bolder",
                    border: "1px solid lightgrey",
                    fontSize: "20px",
                  }}
                >
                  <Input
                    id=""
                    name="name"
                    value={formData?.["ProstateCancer"]?.years}
                    onChange={(e) => {
                      handleYearsChange("ProstateCancer", e.target.value);
                    }}
                    placeholder="Enter years"
                    type="number"
                    style={{
                      borderColor: fieldErrors?.ProstateCancer?.years
                        ? "red"
                        : "lightgrey",
                    }}
                    className="mt-2"
                  />
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bolder",
                    border: "1px solid lightgrey",
                    fontSize: "20px",
                  }}
                >
                  <Input
                    id=""
                    name="name"
                    value={formData?.["ProstateCancer"]?.patientsHelped}
                    onChange={(e) => {
                      handlePatientsHelpedChange(
                        "ProstateCancer",
                        e.target.value
                      );
                    }}
                    style={{
                      borderColor: fieldErrors?.ProstateCancer?.patientsHelped
                        ? "red"
                        : "lightgrey",
                    }}
                    placeholder="Enter number of patients you have helped"
                    type="number"
                    className="mt-2"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Label>Cervical Cancer</Label>
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Input
                    type="select"
                    id=""
                    name="educationalQualification"
                    onChange={(e) => {
                      handleGenderChange("CervicalCancer", e.target.value);
                    }}
                    value={formData?.["CervicalCancer"]?.gender}
                    style={{
                      borderColor: fieldErrors?.CervicalCancer?.gender
                        ? "red"
                        : "lightgrey",
                    }}
                    className="mt-2"
                  >
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="both">Both</option>
                  </Input>
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Row>
                    <Col md={6}>
                      <div className="age-group-label">0-3</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="0-3"
                              checked={isChecked("CervicalCancer", "0-3")}
                              sx={{
                                color: fieldErrors?.CervicalCancer?.ageGroup
                                  ? "red"
                                  : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.CervicalCancer?.ageGroup
                                    ? "red"
                                    : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange(
                              "CervicalCancer",
                              e.target.value
                            );
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <div className="age-group-label">3-13</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="3-13"
                              checked={isChecked("CervicalCancer", "3-13")}
                              sx={{
                                color: fieldErrors?.CervicalCancer?.ageGroup
                                  ? "red"
                                  : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.CervicalCancer?.ageGroup
                                    ? "red"
                                    : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange(
                              "CervicalCancer",
                              e.target.value
                            );
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="age-group-label">13-21</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="13-21"
                              checked={isChecked("CervicalCancer", "13-21")}
                              sx={{
                                color: fieldErrors?.CervicalCancer?.ageGroup
                                  ? "red"
                                  : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.CervicalCancer?.ageGroup
                                    ? "red"
                                    : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange(
                              "CervicalCancer",
                              e.target.value
                            );
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <div className="age-group-label">&gt;21</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value=">21"
                              checked={isChecked("CervicalCancer", ">21")}
                              sx={{
                                color: fieldErrors?.CervicalCancer?.ageGroup
                                  ? "red"
                                  : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.CervicalCancer?.ageGroup
                                    ? "red"
                                    : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange(
                              "CervicalCancer",
                              e.target.value
                            );
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bolder",
                    border: "1px solid lightgrey",
                    fontSize: "20px",
                  }}
                >
                  <Input
                    id=""
                    name="name"
                    value={formData?.["CervicalCancer"]?.years}
                    onChange={(e) => {
                      handleYearsChange("CervicalCancer", e.target.value);
                    }}
                    style={{
                      borderColor: fieldErrors?.CervicalCancer?.years
                        ? "red"
                        : "lightgrey",
                    }}
                    placeholder="Enter years"
                    type="number"
                    className="mt-2"
                  />
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bolder",
                    border: "1px solid lightgrey",
                    fontSize: "20px",
                  }}
                >
                  <Input
                    id=""
                    name="name"
                    value={formData?.["CervicalCancer"]?.patientsHelped}
                    onChange={(e) => {
                      handlePatientsHelpedChange(
                        "CervicalCancer",
                        e.target.value
                      );
                    }}
                    style={{
                      borderColor: fieldErrors?.CervicalCancer?.patientsHelped
                        ? "red"
                        : "lightgrey",
                    }}
                    placeholder="Enter number of patients you have helped"
                    type="number"
                    className="mt-2"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Label>Oral Cancer</Label>
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Input
                    type="select"
                    id=""
                    name="educationalQualification"
                    onChange={(e) => {
                      handleGenderChange("OralCancer", e.target.value);
                    }}
                    value={formData?.["OralCancer"]?.gender}
                    style={{
                      borderColor: fieldErrors?.OralCancer?.gender
                        ? "red"
                        : "lightgrey",
                    }}
                    className="mt-2"
                  >
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="both">Both</option>
                  </Input>
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid lightgrey",
                  }}
                >
                  <Row>
                    <Col>
                      <div className="age-group-label">0-3</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="0-3"
                              checked={isChecked("OralCancer", "0-3")}
                              sx={{
                                color: fieldErrors?.OralCancer?.ageGroup
                                  ? "red"
                                  : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.OralCancer?.ageGroup
                                    ? "red"
                                    : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("OralCancer", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <div className="age-group-label">3-13</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="3-13"
                              checked={isChecked("OralCancer", "3-13")}
                              sx={{
                                color: fieldErrors?.OralCancer?.ageGroup
                                  ? "red"
                                  : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.OralCancer?.ageGroup
                                    ? "red"
                                    : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("OralCancer", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="age-group-label">13-21</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="13-21"
                              checked={isChecked("OralCancer", "13-21")}
                              sx={{
                                color: fieldErrors?.OralCancer?.ageGroup
                                  ? "red"
                                  : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.OralCancer?.ageGroup
                                    ? "red"
                                    : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("OralCancer", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <div className="age-group-label">&gt;21</div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value=">21"
                              checked={isChecked("OralCancer", ">21")}
                              sx={{
                                color: fieldErrors?.OralCancer?.ageGroup
                                  ? "red"
                                  : "default",
                                "&.Mui-checked": {
                                  color: fieldErrors?.OralCancer?.ageGroup
                                    ? "red"
                                    : "blue", // Ensure checked state is blue
                                },
                              }}
                            />
                          }
                          label=""
                          onChange={(e) => {
                            handleAgeGroupChange("OralCancer", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bolder",
                    border: "1px solid lightgrey",
                    fontSize: "20px",
                  }}
                >
                  <Input
                    id=""
                    name="name"
                    value={formData?.["OralCancer"]?.years}
                    onChange={(e) => {
                      handleYearsChange("OralCancer", e.target.value);
                    }}
                    placeholder="Enter years"
                    type="number"
                    style={{
                      borderColor: fieldErrors?.OralCancer?.years
                        ? "red"
                        : "lightgrey",
                    }}
                    className="mt-2"
                  />
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bolder",
                    border: "1px solid lightgrey",
                    fontSize: "20px",
                  }}
                >
                  <Input
                    id=""
                    name="name"
                    value={formData?.["OralCancer"]?.patientsHelped}
                    onChange={(e) => {
                      handlePatientsHelpedChange("OralCancer", e.target.value);
                    }}
                    placeholder="Enter number of patients you have helped"
                    type="number"
                    style={{
                      borderColor: fieldErrors?.OralCancer?.patientsHelped
                        ? "red"
                        : "lightgrey",
                    }}
                    className="mt-2"
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <hr style={{ border: "1px solid #000000" }}></hr>
        <Row>
          <Col md={12}>
            <FormGroup>
              <Label
                style={{
                  color: fieldErrors?.nutritionConsultingForDiseasesForClients
                    ? "red"
                    : "inherit",
                }}
              >
                For which of the following diseases you would be interested in
                providing diet consulting to our clients? (Please note that most
                of our clients would need Preventive Diet solutions rather than
                cure solutions as our aim is to prevent or delay the onset of
                these diseases through lifestyle changes).
              </Label>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={nutritionConsultingForDiseasesForClients?.includes(
                    "CVD"
                  )}
                  onChange={(e) => {
                    handleNutritionConsultingForDiseasesForClients(e);
                  }}
                />
              }
              label="CVD"
              value="CVD"
            />
          </Col>
          <Col md={3}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={nutritionConsultingForDiseasesForClients?.includes(
                    "Hypertension"
                  )}
                  onChange={(e) => {
                    handleNutritionConsultingForDiseasesForClients(e);
                  }}
                />
              }
              label="Hypertension"
              value="Hypertension"
            />
          </Col>
          <Col md={3}>
            {" "}
            <FormControlLabel
              control={
                <Checkbox
                  checked={nutritionConsultingForDiseasesForClients?.includes(
                    "ObesityWeightLoss"
                  )}
                  onChange={(e) => {
                    handleNutritionConsultingForDiseasesForClients(e);
                  }}
                />
              }
              label="Obesity/Weight Loss"
              value="ObesityWeightLoss"
            />
          </Col>
          <Col md={3}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={nutritionConsultingForDiseasesForClients?.includes(
                    "Diabetes"
                  )}
                  onChange={(e) => {
                    handleNutritionConsultingForDiseasesForClients(e);
                  }}
                />
              }
              label="Diabetes"
              value="Diabetes"
            />
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={nutritionConsultingForDiseasesForClients?.includes(
                    "Colorectal Cancer"
                  )}
                  onChange={(e) => {
                    handleNutritionConsultingForDiseasesForClients(e);
                  }}
                />
              }
              label="Colorectal Cancer"
              value="Colorectal Cancer"
            />
          </Col>
          <Col md={3}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={nutritionConsultingForDiseasesForClients?.includes(
                    "Prostate Cancer"
                  )}
                  onChange={(e) => {
                    handleNutritionConsultingForDiseasesForClients(e);
                  }}
                />
              }
              label="Prostate Cancer"
              value="Prostate Cancer"
            />
          </Col>
          <Col md={3}>
            {" "}
            <FormControlLabel
              control={
                <Checkbox
                  checked={nutritionConsultingForDiseasesForClients?.includes(
                    "Cervical Cancer"
                  )}
                  onChange={(e) => {
                    handleNutritionConsultingForDiseasesForClients(e);
                  }}
                />
              }
              label="Cervical Cancer"
              value="Cervical Cancer"
            />
          </Col>
          <Col md={3}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={nutritionConsultingForDiseasesForClients?.includes(
                    "Oral Cancer"
                  )}
                  onChange={(e) => {
                    handleNutritionConsultingForDiseasesForClients(e);
                  }}
                />
              }
              label="Oral Cancer"
              value="Oral Cancer"
            />
          </Col>
        </Row>
        <Row>
          {console.log("other", diseasesOtherValue)}
          <Col md={4}>
            <Label>Others : </Label>
            <Input
              id="otherDisease"
              name="otherDisease"
              value={diseasesOtherValue}
              onChange={handleDiseasesOtherValue}
              onBlur={handleOtherDiseases}
              placeholder="Enter other diseases"
              type="text"
              className="mt-2"
            />
          </Col>
        </Row>
        <hr style={{ border: "1px solid #000000" }}></hr>
        <Row>
          <Col>
            <span>
              Are you comfortable working with children of all ages, guiding
              them towards a healthier and better lifestyle. Please elaborate
              your work with children in the past (if any).
            </span>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Input
              id=""
              name="name"
              value={historyOfWorkingWithChild}
              onChange={(e) => {
                handleHistoryOfWorkingWithChild(e);
              }}
              placeholder="Elaborate your work with children in the past"
              type="textarea"
              style={{
                borderColor: fieldErrors?.historyOfWorkingWithChild
                  ? "red"
                  : "lightgrey",
              }}
              className="mt-2"
            />
          </Col>
        </Row>
        <hr style={{ border: "1px solid #000000" }}></hr>
        <Row>
          <Col>
            <span>
              Do you follow any specific methodology or national/international
              dietary guidelines while providing diet consultation? Please
              explain briefly your approach.
            </span>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Input
              id=""
              name="name"
              value={detailsOfMethodologyOrDietaryGuidelinesFollowed}
              onChange={(e) => {
                handleDetailsOfMethodologyOrDietaryGuidelinesFollowed(e);
              }}
              placeholder="Specific methodology or national/international dietary guidelines"
              type="textarea"
              style={{
                borderColor:
                  fieldErrors?.detailsOfMethodologyOrDietaryGuidelinesFollowed
                    ? "red"
                    : "lightgrey",
              }}
              className="mt-2"
            />
          </Col>
        </Row>
        <hr style={{ border: "1px solid #000000" }}></hr>
        <Row>
          <Col>
            <span
              style={{
                color: fieldErrors?.genderPreference ? "red" : "inherit",
              }}
            >
              Do you find yourself more comfortable working with specific gender
              (male vs female) or you are equally comfortable working with both
              gender?
            </span>
          </Col>
        </Row>
        <Row>
          {console.log("genderpre", genderPreference)}
          <Col md={6}>
            <FormGroup>
              <Input
                name="genderPreference"
                value="male"
                checked={genderPreference === "male"}
                onChange={(e) => {
                  handleGenderPreference(e);
                }}
                type="radio"
              />
              &nbsp;
              <Label check className="ml-1">
                Male
              </Label>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <Input
                name="genderPreference"
                value="female"
                checked={genderPreference === "female"}
                onChange={(e) => {
                  handleGenderPreference(e);
                }}
                type="radio"
              />
              &nbsp;
              <Label check className="ml-1">
                Female
              </Label>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <Input
                name="genderPreference"
                value="both"
                checked={genderPreference === "both"}
                onChange={(e) => {
                  handleGenderPreference(e);
                }}
                type="radio"
              />
              &nbsp;
              <Label check className="ml-1">
                Both
              </Label>
            </FormGroup>
          </Col>
        </Row>
        <hr style={{ border: "1px solid #000000" }}></hr>
        <Grid container spacing={2} mb={2}>
          <Grid item>
            <Label>
              Based on your past experiences, rate your own performance in terms
              of positive outcome for your clients, on a scale of 1 to 5 (5=
              Outstanding, 4= Excellent, 3= Good 2= Fair 1= Not sure, 0= No
              experience)
            </Label>
          </Grid>
        </Grid>

        <Row>
          <Col lg={5}></Col>
          <Col lg={7}>
            <div style={{ display: "flex", gap: "85px" }}>
              <div>0</div>
              <div>1</div>
              <div>2</div>
              <div>3</div>
              <div>4</div>
              <div>5</div>
            </div>
          </Col>
        </Row>
        <FormControl style={{ width: "-webkit-fill-available" }}>
          <Row>
            <Col lg={5}>
              <Label
                id="demo-radio-buttons-group-label"
                // style={{
                //   color: fieldErrors.avoidProcessedMeat ? "red" : "inherit",
                // }}
              >
                1. Children (from Birth to 3 years)
              </Label>
            </Col>
            <Col lg={7}>
              <div style={{ display: "flex", gap: "75px" }}>
                <Input
                  name="0-3"
                  value="No experience"
                  checked={
                    childrenFromBirthTillThreeYearsAge === "No experience"
                  }
                  control={<Radio />}
                  onChange={(e) => {
                    setChildrenFromBirthTillThreeYearsAge("No experience");
                  }}
                  type="radio"
                />
                <Input
                  name="0-3"
                  value="Not sure"
                  checked={childrenFromBirthTillThreeYearsAge === "Not sure"}
                  control={<Radio />}
                  onChange={(e) => {
                    setChildrenFromBirthTillThreeYearsAge("Not sure");
                  }}
                  type="radio"
                />
                <Input
                  name="0-3"
                  value="Fair"
                  checked={childrenFromBirthTillThreeYearsAge === "Fair"}
                  control={<Radio />}
                  onChange={(e) => {
                    setChildrenFromBirthTillThreeYearsAge("Fair");
                  }}
                  type="radio"
                />
                <Input
                  name="0-3"
                  value="Good"
                  checked={childrenFromBirthTillThreeYearsAge === "Good"}
                  control={<Radio />}
                  onChange={(e) => {
                    setChildrenFromBirthTillThreeYearsAge("Good");
                  }}
                  type="radio"
                />
                <Input
                  name="0-3"
                  value="Excellent"
                  checked={childrenFromBirthTillThreeYearsAge === "Excellent"}
                  control={<Radio />}
                  onChange={(e) => {
                    setChildrenFromBirthTillThreeYearsAge("Excellent");
                  }}
                  type="radio"
                />
                <Input
                  name="0-3"
                  value="Outstanding"
                  checked={childrenFromBirthTillThreeYearsAge === "Outstanding"}
                  control={<Radio />}
                  onChange={(e) => {
                    setChildrenFromBirthTillThreeYearsAge("Outstanding");
                  }}
                  type="radio"
                />
              </div>
            </Col>
          </Row>
        </FormControl>
        <FormControl style={{ width: "-webkit-fill-available" }}>
          <Row>
            <Col lg={5}>
              <Label
                id="demo-radio-buttons-group-label"
                // style={{
                //   color: fieldErrors.avoidProcessedMeat ? "red" : "inherit",
                // }}
              >
                2. Children (2-12 years)
              </Label>
            </Col>
            <Col lg={7}>
              <div style={{ display: "flex", gap: "75px" }}>
                <Input
                  name="2-12"
                  value="No experience"
                  checked={childrenFromTwoToTwelveYearsAge === "No experience"}
                  control={<Radio />}
                  onChange={(e) => {
                    setChildrenFromTwoToTwelveYearsAge("No experience");
                  }}
                  type="radio"
                />
                <Input
                  name="2-12"
                  value="Not sure"
                  checked={childrenFromTwoToTwelveYearsAge === "Not sure"}
                  control={<Radio />}
                  onChange={(e) => {
                    setChildrenFromTwoToTwelveYearsAge("Not sure");
                  }}
                  type="radio"
                />
                <Input
                  name="2-12"
                  value="Fair"
                  checked={childrenFromTwoToTwelveYearsAge === "Fair"}
                  control={<Radio />}
                  onChange={(e) => {
                    setChildrenFromTwoToTwelveYearsAge("Fair");
                  }}
                  type="radio"
                />
                <Input
                  name="2-12"
                  value="Good"
                  checked={childrenFromTwoToTwelveYearsAge === "Good"}
                  control={<Radio />}
                  onChange={(e) => {
                    setChildrenFromTwoToTwelveYearsAge("Good");
                  }}
                  type="radio"
                />
                <Input
                  name="2-12"
                  value="Excellent"
                  checked={childrenFromTwoToTwelveYearsAge === "Excellent"}
                  control={<Radio />}
                  onChange={(e) => {
                    setChildrenFromTwoToTwelveYearsAge(e);
                  }}
                  type="radio"
                />
                <Input
                  name="2-12"
                  value="Outstanding"
                  checked={childrenFromTwoToTwelveYearsAge === "Outstanding"}
                  control={<Radio />}
                  onChange={(e) => {
                    setChildrenFromTwoToTwelveYearsAge("Outstanding");
                  }}
                  type="radio"
                />
              </div>
            </Col>
          </Row>
        </FormControl>
        <FormControl style={{ width: "-webkit-fill-available" }}>
          <Row>
            <Col lg={5}>
              <Label
                id="demo-radio-buttons-group-label"
                style={{
                  color: fieldErrors?.avoidProcessedMeat ? "red" : "inherit",
                }}
              >
                3. Children (13-21 years)
              </Label>
            </Col>
            <Col lg={7}>
              <div style={{ display: "flex", gap: "75px" }}>
                <Input
                  name="13-21"
                  value="No experience"
                  checked={
                    childrenFromThirteenToTwentyYearsAge === "No experience"
                  }
                  control={<Radio />}
                  onChange={(e) => {
                    setChildrenFromThirteenToTwentyYearsAge("No experience");
                  }}
                  type="radio"
                />
                <Input
                  name="13-21"
                  value="Not sure"
                  checked={childrenFromThirteenToTwentyYearsAge === "Not sure"}
                  control={<Radio />}
                  onChange={(e) => {
                    setChildrenFromThirteenToTwentyYearsAge("Not sure");
                  }}
                  type="radio"
                />
                <Input
                  name="13-21"
                  value="Fair"
                  checked={childrenFromThirteenToTwentyYearsAge === "Fair"}
                  control={<Radio />}
                  onChange={(e) => {
                    setChildrenFromThirteenToTwentyYearsAge("Fair");
                  }}
                  type="radio"
                />
                <Input
                  name="13-21"
                  value="Good"
                  checked={childrenFromThirteenToTwentyYearsAge === "Good"}
                  control={<Radio />}
                  onChange={(e) => {
                    setChildrenFromThirteenToTwentyYearsAge("Good");
                  }}
                  type="radio"
                />
                <Input
                  name="13-21"
                  value="Excellent"
                  checked={childrenFromThirteenToTwentyYearsAge === "Excellent"}
                  control={<Radio />}
                  onChange={(e) => {
                    setChildrenFromThirteenToTwentyYearsAge("Excellent");
                  }}
                  type="radio"
                />
                <Input
                  name="13-21"
                  value="Outstanding"
                  checked={
                    childrenFromThirteenToTwentyYearsAge === "Outstanding"
                  }
                  control={<Radio />}
                  onChange={(e) => {
                    setChildrenFromThirteenToTwentyYearsAge("Outstanding");
                  }}
                  type="radio"
                />
              </div>
            </Col>
          </Row>
        </FormControl>
        <FormControl style={{ width: "-webkit-fill-available" }}>
          <Row>
            <Col lg={5}>
              <Label
                id="demo-radio-buttons-group-label"
                style={{
                  color: fieldErrors?.avoidProcessedMeat ? "red" : "inherit",
                }}
              >
                4. Adults-Male (21-60)
              </Label>
            </Col>
            <Col lg={7}>
              <div style={{ display: "flex", gap: "75px" }}>
                <Input
                  name="21-60"
                  value="No experience"
                  checked={
                    adultMaleFromTwentyOneToSixtyYearsAge === "No experience"
                  }
                  control={<Radio />}
                  onChange={(e) => {
                    setAdultMaleFromTwentyOneToSixtyYearsAge("No experience");
                  }}
                  type="radio"
                />
                <Input
                  name="21-60"
                  value="Not sure"
                  checked={adultMaleFromTwentyOneToSixtyYearsAge === "Not sure"}
                  control={<Radio />}
                  onChange={(e) => {
                    setAdultMaleFromTwentyOneToSixtyYearsAge("Not sure");
                  }}
                  type="radio"
                />
                <Input
                  name="21-60"
                  value="Fair"
                  checked={adultMaleFromTwentyOneToSixtyYearsAge === "Fair"}
                  control={<Radio />}
                  onChange={(e) => {
                    setAdultMaleFromTwentyOneToSixtyYearsAge("Fair");
                  }}
                  type="radio"
                />
                <Input
                  name="21-60"
                  value="Good"
                  checked={adultMaleFromTwentyOneToSixtyYearsAge === "Good"}
                  control={<Radio />}
                  onChange={(e) => {
                    setAdultMaleFromTwentyOneToSixtyYearsAge("Good");
                  }}
                  type="radio"
                />
                <Input
                  name="21-60"
                  value="Excellent"
                  checked={
                    adultMaleFromTwentyOneToSixtyYearsAge === "Excellent"
                  }
                  control={<Radio />}
                  onChange={(e) => {
                    setAdultMaleFromTwentyOneToSixtyYearsAge("Excellent");
                  }}
                  type="radio"
                />
                <Input
                  name="21-60"
                  value="Outstanding"
                  checked={
                    adultMaleFromTwentyOneToSixtyYearsAge === "Outstanding"
                  }
                  control={<Radio />}
                  onChange={(e) => {
                    setAdultMaleFromTwentyOneToSixtyYearsAge("Outstanding");
                  }}
                  type="radio"
                />
              </div>
            </Col>
          </Row>
        </FormControl>
        <FormControl style={{ width: "-webkit-fill-available" }}>
          <Row>
            <Col lg={5}>
              <Label
                id="demo-radio-buttons-group-label"
                style={{
                  color: fieldErrors?.avoidProcessedMeat ? "red" : "inherit",
                }}
              >
                5. Adult-Female (21-60)
              </Label>
            </Col>
            <Col lg={7}>
              <div style={{ display: "flex", gap: "75px" }}>
                <Input
                  name="21-60F"
                  value="No experience"
                  checked={
                    adultFemaleFromTwentyOneToSixtyYearsAge === "No experience"
                  }
                  control={<Radio />}
                  onChange={(e) => {
                    setAdultFemaleFromTwentyOneToSixtyYearsAgee(
                      "No experience"
                    );
                  }}
                  type="radio"
                />
                <Input
                  name="21-60F"
                  value="Not sure"
                  checked={
                    adultFemaleFromTwentyOneToSixtyYearsAge === "Not sure"
                  }
                  control={<Radio />}
                  onChange={(e) => {
                    setAdultFemaleFromTwentyOneToSixtyYearsAgee("Not sure");
                  }}
                  type="radio"
                />
                <Input
                  name="21-60F"
                  value="Fair"
                  checked={adultFemaleFromTwentyOneToSixtyYearsAge === "Fair"}
                  control={<Radio />}
                  onChange={(e) => {
                    setAdultFemaleFromTwentyOneToSixtyYearsAgee("Fair");
                  }}
                  type="radio"
                />
                <Input
                  name="21-60F"
                  value="Good"
                  checked={adultFemaleFromTwentyOneToSixtyYearsAge === "Good"}
                  control={<Radio />}
                  onChange={(e) => {
                    setAdultFemaleFromTwentyOneToSixtyYearsAgee("Good");
                  }}
                  type="radio"
                />
                <Input
                  name="21-60F"
                  value="Excellent"
                  checked={
                    adultFemaleFromTwentyOneToSixtyYearsAge === "Excellent"
                  }
                  control={<Radio />}
                  onChange={(e) => {
                    setAdultFemaleFromTwentyOneToSixtyYearsAgee("Excellent");
                  }}
                  type="radio"
                />
                <Input
                  name="21-60F"
                  value="Outstanding"
                  checked={
                    adultFemaleFromTwentyOneToSixtyYearsAge === "Outstanding"
                  }
                  control={<Radio />}
                  onChange={(e) => {
                    setAdultFemaleFromTwentyOneToSixtyYearsAgee("Outstanding");
                  }}
                  type="radio"
                />
              </div>
            </Col>
          </Row>
        </FormControl>
        <FormControl style={{ width: "-webkit-fill-available" }}>
          <Row>
            <Col lg={5}>
              <Label
                id="demo-radio-buttons-group-label"
                style={{
                  color: fieldErrors?.avoidProcessedMeat ? "red" : "inherit",
                }}
              >
                6. Seniors (Age &gt;60 years)
              </Label>
            </Col>
            <Col lg={7}>
              <div style={{ display: "flex", gap: "75px" }}>
                <Input
                  name="<60"
                  value="No experience"
                  checked={seniorsOfAgeGreaterThenSixty === "No experience"}
                  control={<Radio />}
                  onChange={(e) => {
                    setSeniorsOfAgeGreaterThenSixty("No experience");
                  }}
                  type="radio"
                />
                <Input
                  name="<60"
                  value="Not sure"
                  checked={seniorsOfAgeGreaterThenSixty === "Not sure"}
                  control={<Radio />}
                  onChange={(e) => {
                    setSeniorsOfAgeGreaterThenSixty("Not sure");
                  }}
                  type="radio"
                />
                <Input
                  name="<60"
                  value="Fair"
                  checked={seniorsOfAgeGreaterThenSixty === "Fair"}
                  control={<Radio />}
                  onChange={(e) => {
                    setSeniorsOfAgeGreaterThenSixty("Fair");
                  }}
                  type="radio"
                />
                <Input
                  name="<60"
                  value="Good"
                  checked={seniorsOfAgeGreaterThenSixty === "Good"}
                  control={<Radio />}
                  onChange={(e) => {
                    setSeniorsOfAgeGreaterThenSixty("Good");
                  }}
                  type="radio"
                />
                <Input
                  name="<60"
                  value="Excellent"
                  checked={seniorsOfAgeGreaterThenSixty === "Excellent"}
                  control={<Radio />}
                  onChange={(e) => {
                    setSeniorsOfAgeGreaterThenSixty("Excellent");
                  }}
                  type="radio"
                />
                <Input
                  name="<60"
                  value="Outstanding"
                  checked={seniorsOfAgeGreaterThenSixty === "Outstanding"}
                  control={<Radio />}
                  onChange={(e) => {
                    setSeniorsOfAgeGreaterThenSixty("Outstanding");
                  }}
                  type="radio"
                />
              </div>
            </Col>
          </Row>
        </FormControl>

        <hr style={{ border: "1px solid #000000" }}></hr>
        <Row>
          <Col>
            <span>
              Please cut and paste below three (3) of the most outstanding
              comments/testimonials you have received from your clients in the
              past for the services you have provided to them.
            </span>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <span>
              <label
                htmlFor="fileUpload"
                style={{
                  cursor: "pointer",
                  color: "rgb(83, 83, 233)",
                  textDecoration: "underline",
                  marginLeft: "20px",
                }}
              >
                Attach client testimonials
              </label>
            </span>{" "}
            <div>
              <input
                type="file"
                accept=".jpg, .jpeg, .pdf, .png"
                id="fileUpload"
                className="file-upload-input"
                name="isoCertificate"
                // onChange={(event) => handleFileSubmit(event, row.id)}
                style={{ display: "none" }}
                onChange={(event) => AttachTestimonial(event)}
                multiple
              />
            </div>
            &nbsp;&nbsp;&nbsp;
            {isoCertificates.length > 0
              ? isoCertificates.map((link, index) => <p>{link}</p>)
              : ""}
          </Col>
        </Row>
        <div>
          <b>Reports: </b>
          {lastThreeTestimonials?.length > 0 ? (
            <ol className="document-list">
              {lastThreeTestimonials?.map((item, index) => (
                <li key={index}>
                  <span
                    style={{
                      color: "blue",
                      textDecoration: "none",
                      cursor: "default",
                      fontSize: "medium",
                    }}
                  >
                    {item.sampleTestLink || item}
                  </span>
                </li>
              ))}
            </ol>
          ) : (
            <span>No reports available.</span>
          )}
        </div>
        {testimonials?.length < 3 ? (
          <Row>
            <Col md={12}>
              <Label>
                If you would rather mention the testimonials, then do so in the
                space provided below. Just cut and paste up to three (3)
                testimonials. Click on “Add testimonial” below.
              </Label>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col md={12}>
              <br />
              <Label>
                <b>Testimonials : </b>
              </Label>
            </Col>
          </Row>
        )}
        <Row>
          <Col md={4}>
            {testimonials?.length < 3 && (
              <Button
                type="button"
                className="btn btn-secondary"
                onClick={addTestimonial}
              >
                Add Testimonial
              </Button>
            )}
          </Col>
          <Row>
            {}
            {testimonials?.map((testimonial, index) => (
              <Col md={12} key={index}>
                <Input
                  id={`testimonial-${index}`}
                  name={`testimonial-${index}`}
                  value={testimonial}
                  onChange={(e) => handleInputChange(index, e.target.value)}
                  placeholder="Enter Testimonial"
                  type="textarea"
                  className="mt-2"
                  style={{
                    borderColor: "lightgrey",
                  }}
                />
              </Col>
            ))}
          </Row>
        </Row>
        <hr style={{ border: "1px solid #000000" }}></hr>
        <h4>Reference</h4>
        <hr style={{ width: "10%", marginTop: "3px", height: "2px" }}></hr>
        <Row>
          <Col>
            <span
              style={{
                color: fieldErrors?.sharingReferences ? "red" : "inherit",
              }}
            >
              Would you be open to sharing 3 references of your past or current
              clients (1= children, 1= Female Adult and 1 = Male Adult) who can
              give us honest feedback of their experiences in working with you
              for your Nutrition consulting services?
            </span>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Input
                name="serviceProvideWithSameAddress"
                type="radio"
                value="true"
                checked={sharingReferences === true}
                onChange={(e) => {
                  handlesharingReferences(e);
                }}
                style={{ cursor: "pointer" }}
              />
              &nbsp;
              <Label check className="ml-1">
                Yes
              </Label>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <Input
                name="serviceProvideWithSameAddress"
                type="radio"
                value="false"
                checked={sharingReferences === false}
                onChange={(e) => {
                  handlesharingReferences(e);
                }}
                style={{ cursor: "pointer" }}
              />
              &nbsp;
              <Label check className="ml-1">
                No
              </Label>
            </FormGroup>
          </Col>
        </Row>
        <br></br>
        {(showReferences || sharingReferences) && (
          <>
            <Label>
              Please provide below their contact information and let them know
              that will be receiving a call from Living Scope Health Marketing
              team member. It will be a quick conversation only and we won’t
              take much of their time.
            </Label>
            <h5>Reference # 1</h5>
            <Row style={{ marginTop: "20px" }}>
              <Col md={4}>
                <Label style={{ marginBottom: "0px" }}>Name : </Label>
                <Input
                  id=""
                  name="name"
                  value={referenceDetail?.reference1?.name}
                  onChange={(e) => {
                    handleReferenceUpdate("reference1", "name", e.target.value);
                  }}
                  placeholder="Enter Name"
                  type="text"
                  className="mt-2"
                  style={{
                    borderColor: fieldErrors?.reference1Name
                      ? "red"
                      : "inherit",
                  }}
                />
              </Col>
              <Col md={4}>
                <Label style={{ marginBottom: "0px" }}>Mobile : </Label>
                <Input
                  id=""
                  name="name"
                  value={referenceDetail?.reference1?.mobile}
                  onChange={(e) => {
                    handleReferenceUpdate(
                      "reference1",
                      "mobile",
                      e.target.value
                    );
                  }}
                  placeholder="Enter Mobile"
                  type="text"
                  className="mt-2"
                  style={{
                    borderColor: fieldErrors?.reference1Mobile
                      ? "red"
                      : "inherit",
                  }}
                />
              </Col>
            </Row>
            <hr style={{ width: "80%", height: "3px" }}></hr>
            <h5>Reference # 2</h5>
            <Row style={{ marginTop: "20px" }}>
              <Col md={4}>
                <Label style={{ marginBottom: "0px" }}>Name : </Label>
                <Input
                  id=""
                  name="name"
                  value={referenceDetail?.reference2?.name}
                  onChange={(e) => {
                    handleReferenceUpdate("reference2", "name", e.target.value);
                  }}
                  placeholder="Enter Name"
                  type="text"
                  className="mt-2"
                />
              </Col>
              <Col md={4}>
                <Label style={{ marginBottom: "0px" }}>Mobile : </Label>
                <Input
                  id=""
                  name="name"
                  value={referenceDetail?.reference2?.mobile}
                  onChange={(e) => {
                    handleReferenceUpdate(
                      "reference2",
                      "mobile",
                      e.target.value
                    );
                  }}
                  placeholder="Enter Mobile"
                  type="text"
                  className="mt-2"
                />
              </Col>
            </Row>
            <hr style={{ width: "80%", height: "3px" }}></hr>
            <h5>Reference # 3</h5>
            <Row style={{ marginTop: "20px" }}>
              <Col md={4}>
                <Label style={{ marginBottom: "0px" }}>Name : </Label>
                <Input
                  id=""
                  name="name"
                  value={referenceDetail?.reference3?.name}
                  onChange={(e) => {
                    handleReferenceUpdate("reference3", "name", e.target.value);
                  }}
                  placeholder="Enter Name"
                  type="text"
                  className="mt-2"
                />
              </Col>
              <Col md={4}>
                <Label style={{ marginBottom: "0px" }}>Mobile : </Label>
                <Input
                  id=""
                  name="name"
                  value={referenceDetail?.reference3?.mobile}
                  onChange={(e) => {
                    handleReferenceUpdate(
                      "reference3",
                      "mobile",
                      e.target.value
                    );
                  }}
                  placeholder="Enter Mobile"
                  type="text"
                  className="mt-2"
                />
              </Col>
            </Row>
          </>
        )}
        <hr style={{ border: "1px solid #000000" }}></hr>

        <Row>
          <Col>
            <span
              style={{
                color: fieldErrors?.preferredDayAndTime ? "red" : "inherit",
              }}
            >
              Please check below your preferred time to provide diet consulting
              services to our clients. You will be later given a weekly calendar
              to manage your own preferred schedule on our Uncurl: health
              portal. Our clients will book your consultation based on your
              availability only.{" "}
            </span>
          </Col>
        </Row>
        <Row>
          <Col>
            <br />
            <FormGroup>
              <Label for="weekdays">Select Weekdays:</Label>
              <Row>
                <Col md={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={preferredDayAndTime?.Weekdays?.includes(
                          "Sunday"
                        )}
                        onChange={(e) => {
                          handleWeekDays(e);
                        }}
                      />
                    }
                    label="Sunday"
                    value="Sunday"
                  />
                </Col>
                <Col md={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={preferredDayAndTime?.Weekdays?.includes(
                          "Monday"
                        )}
                        onChange={(e) => {
                          handleWeekDays(e);
                        }}
                      />
                    }
                    label="Monday"
                    value="Monday"
                  />
                </Col>
                <Col md={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={preferredDayAndTime?.Weekdays?.includes(
                          "Tuesday"
                        )}
                        onChange={(e) => {
                          handleWeekDays(e);
                        }}
                      />
                    }
                    label="Tuesday"
                    value="Tuesday"
                  />
                </Col>
                <Col md={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={preferredDayAndTime?.Weekdays?.includes(
                          "Wednesday"
                        )}
                        onChange={(e) => {
                          handleWeekDays(e);
                        }}
                      />
                    }
                    label="Wednesday"
                    value="Wednesday"
                  />
                </Col>
              </Row>
              <Row>
                <Col md={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={preferredDayAndTime?.Weekdays?.includes(
                          "Thursday"
                        )}
                        onChange={(e) => {
                          handleWeekDays(e);
                        }}
                      />
                    }
                    label="Thursday"
                    value="Thursday"
                  />
                </Col>
                <Col md={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={preferredDayAndTime?.Weekdays?.includes(
                          "Friday"
                        )}
                        onChange={(e) => {
                          handleWeekDays(e);
                        }}
                      />
                    }
                    label="Friday"
                    value="Friday"
                  />
                </Col>
                <Col md={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={preferredDayAndTime?.Weekdays?.includes(
                          "Saturday"
                        )}
                        onChange={(e) => {
                          handleWeekDays(e);
                        }}
                      />
                    }
                    label="Saturday"
                    value="Saturday"
                  />
                </Col>
              </Row>
            </FormGroup>
          </Col>
          <Row>
            <Col md={4}>
              <FormGroup>
                <Label for="time">Select Time:</Label>
                <Input
                  type="time"
                  name="time"
                  id="time"
                  value={preferredDayAndTime?.time}
                  onChange={(e) => {
                    handleTime("time", e.target.value);
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
        </Row>
        <hr style={{ border: "1px solid #000000" }}></hr>
        <Row>
          <Col>
            <Label>
              Please attach below copies of your credentials like Diplomas,
              course certificates, awards, newspaper cuttings etc. that adds to
              your credentials as a leading Nutritionist.
            </Label>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <span>
              <label
                htmlFor="attachCopies"
                style={{
                  cursor: "pointer",
                  color: "rgb(83, 83, 233)",
                  textDecoration: "underline",
                  marginLeft: "20px",
                  color: fieldErrors?.ISOreports ? "red" : "blue",
                }}
              >
                Attach copies
              </label>
            </span>{" "}
            <div>
              <input
                type="file"
                accept=".jpg, .jpeg, .pdf, .png"
                id="attachCopies"
                className="file-upload-input"
                name="attachCopies"
                // onChange={(event) => handleFileSubmit(event, row.id)}
                style={{ display: "none" }}
                onChange={(event) => handleAttachCopies(event)}
                multiple
              />
            </div>
            &nbsp;&nbsp;&nbsp;
            {isoCertificates.length > 0
              ? isoCertificates.map((link, index) => <p>{link}</p>)
              : ""}
            <br></br>
            <div>
              <b>Certificate: </b>
              {lastThreeFiles?.length > 0 ? (
                <ol className="document-list">
                  {lastThreeFiles?.map((item, index) => (
                    <li key={index}>
                      <span
                        style={{
                          color: "blue",
                          textDecoration: "none",
                          cursor: "default",
                          fontSize: "medium",
                        }}
                      >
                        {item.sampleTestLink || item}
                      </span>
                    </li>
                  ))}
                </ol>
              ) : (
                <span>No reports available.</span>
              )}
            </div>
          </Col>
        </Row>
      </Form>
      <center>
        <Button
          onClick={handleSubmit}
          style={{
            marginTop: "4%",
            width: "15%",
            backgroundColor: "rgb(83, 83, 233)",
          }}
        >
          Save
        </Button>
      </center>
    </>
  );
};

export default DieticianGeneralInfo;
