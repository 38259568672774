import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

const DietIntervention = () => {
  const [askFurtherQuestions, setAskFurtherQuestions] = useState("");
  const [questions, setQuestions] = useState([""]);
  const [errors, setErrors] = useState({});
  const handleRadioChange = (e) => {
    setAskFurtherQuestions(e.target.value);
    setQuestions([""]); // reset the question fields when option changes
    setErrors({});
  };

  const handleAddQuestion = () => {
    // if (questions.length < 3) {
    setQuestions([...questions, ""]);
    // }
  };

  const handleQuestionChange = (index, value) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index] = value;
    setQuestions(updatedQuestions);
  };

  const validateForm = () => {
    let formErrors = {};
    if (!askFurtherQuestions) {
      formErrors.askFurtherQuestions = "Please select an option";
    }
    if (askFurtherQuestions === "Yes") {
      questions.forEach((question, index) => {
        if (!question) {
          formErrors[`question${index}`] = "Question is required";
        }
      });
    }
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return false;
    }
    setErrors({});
    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      const userDetails = sessionStorage.getItem("userDetails");
      const parsedData = JSON.parse(userDetails);
      const userType = parsedData.userType;
      const formattedQuestions = questions.map((question) => ({
        question: question,
      }));
      console.log("form questions", questions, formattedQuestions);
      // AxiosInstance.post(
      //   `doctor/sendMassage/${order.orderId}/${userType}`,
      //   formattedQuestions
      // )
      //   .then((response) => {
      //     setIsSubmitted(true);
      //     onReviewSubmit();
      //   })
      //   .catch((err) => {
      //     console.log("err: ", err);
      //   });
    }
  };
  return (
    <div>
      <Box>
        <Typography component="div" variant="h6" sx={{ marginBottom: 1 }}>
          Nutritionist Diet Plan
        </Typography>
        <TextField
          // value={communication?.doctorReview.review}
          value="wegtbttttttttttttttttttttttttttttttgreb brggferffb4tgnerfwfbv"
          multiline
          rows={4}
          fullWidth
          disabled
          sx={{
            "& .MuiOutlinedInput-root.Mui-disabled": {
              backgroundColor: "#d3d3d37a", // Full background color for the input
            },
            "& .MuiInputBase-input.Mui-disabled": {
              WebkitTextFillColor: "black", // Ensures the text is black when disabled
            },
          }}
        />
      </Box>
      <form onSubmit={handleSubmit}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            marginTop: 2,
          }}
        >
          <FormControl
            component="fieldset"
            required
            error={!!errors.additionalTestsRequired}
          >
            <FormLabel component="legend">
              Do you want to ask any further questions to the Nutritionist
              regarding the Diet Plan and his or her review comments ?
            </FormLabel>
            <RadioGroup
              value={askFurtherQuestions}
              onChange={handleRadioChange}
            >
              <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="No" control={<Radio />} label="No" />
            </RadioGroup>
            {errors.askFurtherQuestions && (
              <Typography color="error" variant="caption">
                {errors.askFurtherQuestions}
              </Typography>
            )}
          </FormControl>

          {askFurtherQuestions === "Yes" &&
            questions.map((question, index) => (
              <TextField
                key={index}
                value={question}
                onChange={(e) => handleQuestionChange(index, e.target.value)}
                placeholder={`Question ${index + 1}`}
                required
                fullWidth
                sx={{ marginBottom: 1, marginRight: 1 }}
                error={!!errors[`question${index}`]}
                helperText={errors[`question${index}`]}
              />
            ))}

          {askFurtherQuestions === "Yes" && (
            <Box>
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddQuestion}
              >
                Add More Question
              </Button>
            </Box>
          )}
          <Button type="submit" variant="contained" color="success">
            Submit
          </Button>
        </Box>
      </form>
    </div>
  );
};

export default DietIntervention;
