import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Modal,
  Box,
  Link,
  Grid,
} from "@mui/material";
import { styled } from "@mui/system";

// Styled component for highlighted card
const HighlightedCard = styled(Card)(({ theme, isSelected }) => ({
  backgroundColor: isSelected ? "#f0f0f0" : "#fff",
  border: isSelected ? "2px solid #3f51b5" : "1px solid #ccc",
  cursor: "pointer",
  transition: "all 0.3s ease",
  "&:hover": {
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
  },
  height: "100%",
}));

const DoctorCard = ({ doctorData, onDoctorSelect }) => {
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [bioContent, setBioContent] = useState("");

  const handleOpenModal = (bio) => {
    setBioContent(bio);
    setOpenModal(true);
  };

  const handleCloseModal = () => setOpenModal(false);

  const handleCardClick = (doctor) => {
    setSelectedDoctor(doctor); // Set selected doctor locally
  };

  const handleBookHereClick = () => {
    if (selectedDoctor) {
      onDoctorSelect(selectedDoctor); // Send selected doctor data to parent component
    }
  };

  return (
    <div>
      <Grid container spacing={2} wrap="nowrap">
        {doctorData?.map((doctor) => (
          <Grid item key={doctor.userId}>
            <HighlightedCard
              isSelected={selectedDoctor?.userId === doctor.userId}
              onClick={() => handleCardClick(doctor)}
              sx={{ maxWidth: 345 }}
            >
              <CardContent>
                <Typography
                  variant="h5"
                  style={{ fontWeight: "bold", marginBottom: "8px" }}
                >
                  {doctor.firstName} {doctor.lastName}
                </Typography>
                <Typography
                  variant="body1"
                  style={{ marginBottom: "8px", fontSize: "1.1rem" }}
                >
                  <strong>Practicing As:</strong> {doctor.practicingAs}
                </Typography>
                <Typography
                  variant="body1"
                  style={{ marginBottom: "8px", fontSize: "1.1rem" }}
                >
                  <strong>Degree:</strong> {doctor.highestMedicalDegree}
                </Typography>
                {doctor.awards && (
                  <Typography
                    variant="body1"
                    style={{ marginBottom: "8px", fontSize: "1.1rem" }}
                  >
                    <strong>Awards:</strong> {doctor.awards}
                  </Typography>
                )}
                <Typography
                  variant="body1"
                  style={{ marginBottom: "8px", fontSize: "1.1rem" }}
                >
                  <strong>Test Report View:</strong> ₹{doctor.amount}
                </Typography>
                <Link
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    handleOpenModal(doctor.bio);
                  }}
                  style={{
                    fontSize: "1.05rem",
                    color: "#3f51b5",
                    textDecoration: "underline",
                  }}
                >
                  View Bio
                </Link>
              </CardContent>
            </HighlightedCard>
          </Grid>
        ))}
      </Grid>

      {/* Modal for bio */}
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="bio-modal-title"
        aria-describedby="bio-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            borderRadius: 1,
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="bio-modal-title" variant="h6" component="h2">
            Doctor's Bio
          </Typography>
          <Typography id="bio-modal-description" sx={{ mt: 2 }}>
            {bioContent}
          </Typography>
          <Button onClick={handleCloseModal} sx={{ mt: 2 }} variant="contained">
            Close
          </Button>
        </Box>
      </Modal>

      {selectedDoctor && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <Button
            variant="contained"
            color="secondary"
            onClick={handleBookHereClick}
          >
            Book Here
          </Button>
        </div>
      )}
    </div>
  );
};

export default DoctorCard;
