import React, { useState } from "react";
import {
  Box,
  Button,
  Divider,
  Input,
  TextField,
  Typography,
} from "@mui/material";

const DieticianDietIntervention = () => {
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  return (
    <div>
      <Typography variant="h6" className="mb-3" sx={{ fontWeight: "bold" }}>
        Please Type in your nutrition protocol or you can attach protocol in a
        word doc. using the attch file tab
      </Typography>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Button variant="contained" component="label" color="primary">
          Attach File
          <Input type="file" hidden onChange={handleFileChange} />
        </Button>
        {selectedFile && (
          <Typography
            variant="body2"
            style={{ marginTop: "10px", color: "gray" }}
          >
            {selectedFile.name}
          </Typography>
        )}
      </div>
      <Box
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        mb={2}
        mt={2}
      >
        <Divider style={{ flexGrow: 1 }} />
        <Typography variant="h6" style={{ padding: "0 10px" }}>
          Or
        </Typography>
        <Divider style={{ flexGrow: 1 }} />
      </Box>
      <TextField
        label="Type Your Diet Intervention"
        multiline
        rows={4}
        variant="outlined"
        fullWidth
        style={{ marginBottom: "20px" }}
      />
    </div>
  );
};

export default DieticianDietIntervention;
